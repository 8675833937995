import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useFrame } from "@react-three/fiber";
import { useScroll } from "@react-three/drei";
import { Center } from "@react-three/drei";
import { FontLoader } from "three/addons/loaders/FontLoader.js";
import gsap from "gsap";
import { OBJLoader } from "three/addons/loaders/OBJLoader.js";
import { useGLTF } from "@react-three/drei";
//ok
export default function Letter(props) {
  let cursor = {
    x: 0,
    y: 0,
  };

  const { nodes, material } = useGLTF("/logo/ddm.gltf");

  const letterRef = useRef();
  const scroll = useScroll();

  const tl = useRef();

  useFrame((state, delta) => {
    tl.current.seek(scroll.offset * tl.current.duration());
  });

  useLayoutEffect(() => {
    tl.current = gsap.timeline({
      defaults: { duration: 2, ease: "power1.inOut" },
    });

    if (props.animation) {
      tl.current.to(letterRef.current.rotation, { y: 6 }, "-=18");
      tl.current.to(letterRef.current.scale, { z: 10, x: 10, y: 10 }, "-=18");
      tl.current.to(letterRef.current.scale, { z: 0, x: 0, y: 0 }, 1.2);
    }

    // tl.current.to(letterRef.current.opacity, { z: 10, x: 10, y: 10 });
  }, []);

  useEffect(() => {
    letterRef.current.addEventListener("mousemove", (e) => console.log(e));
  }, []);

  useEffect(() => {
    function handleParallax() {
      window.addEventListener("mousemove", (e) => {
        cursor.x = e.clientX / window.innerWidth;
        cursor.y = e.clientY / window.innerHeight;

        const parallaxX = cursor.x;
        const parallaxY = cursor.y;

        console.log(parallaxX);

        gsap.to(letterRef.current.rotation, {
          x: (parallaxX - 0.5) * 0.4,
        });
        gsap.to(letterRef.current.rotation, {
          z: (parallaxY - 0.5) * 0.3,
        });
      });
    }

    handleParallax();
  }, []);

  return (
    <>
      <group
        ref={letterRef}
        position={props.position}
        scale={props.scale || 0.16}
      >
        <Center rotation={[-4.7, 0, 0]}>
          <mesh geometry={nodes.Scene.children[0].geometry}>
            <meshNormalMaterial />
          </mesh>
          <mesh geometry={nodes.Scene.children[1].geometry}>
            <meshNormalMaterial />
          </mesh>
        </Center>
      </group>
    </>
  );
}
