import React, { Component } from 'react';
import Style from  '../styles/Google.module.css'
function GoogleCompaign() {
    return ( 
        <>
            <section className={Style.googlecomapaign}>
                <div className={`${Style.textcenter} ${Style.cust_container}`}>
                    <h2>Defeat <span>Click Fraud</span>, Supercharge Your<br /> <span>Google Ads Campaigns</span> </h2>
                    <p>Eradicate click fraud, block bots, and optimize ad spend for remarkable results. Shield your campaigns with our cutting-edge techniques.</p>
                    <img src="/images/bannerblack.svg" alt="" /> 
                    <img src="/images/googleapproved.png" alt="" className={Style.adlogo}/>
                </div>
            </section>
        </>
     );
}

export default GoogleCompaign;