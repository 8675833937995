import React, { useEffect, useLayoutEffect, useRef } from "react";
import classes from "../styles/Home.module.css";
import Header from "./Header";
import { useFrame } from "@react-three/fiber";
import gsap from "gsap";
import { BsArrowUpRight } from "react-icons/bs";
import { useScroll } from "@react-three/drei";
import ScrollTrigger from "gsap/ScrollTrigger";
import SectionRequest from "./SectionRequest";
import SectionGlobe from "./SectionGlobe";
import SectionProjects from "./SectionProjects";
import ProjectCounter from "../components/ProjectCounter";
import OurClient from "../components/OurClient";
import Testimonial from "../components/Testimonial";
import GoogleCompaign from './GoogleCompaign';
import Footer from "./Footer";
import Services from "./Services";
import { Helmet } from "react-helmet";

gsap.registerPlugin(ScrollTrigger);

const HomeContent = (props) => {
  const scroll = useScroll();

  const tl = useRef();
  const tl2 = useRef();
  const tl3 = useRef();

  const titleRef = useRef();
  const subTitleRef = useRef();
  const girlRef = useRef();
  const cursorRef = useRef();

  useFrame((state, delta) => {
    tl.current.seek(scroll.offset * tl.current.duration());
  });

  useLayoutEffect(() => {
    tl2.current = gsap.timeline({
      defaults: { duration: 2, ease: "power1.inOut" },
      onUpdate: () => {
        const progress = scroll.offset / (scroll.maxOffset || 1); // Calculate progress based on scroll offset
        tl2.current.progress(progress); // Update timeline progress
      },
    });
    tl2.current.to(titleRef.current, { x: "-10000%" });
  }, []);
  useLayoutEffect(() => {
    tl3.current = gsap.timeline({
      defaults: { duration: 2, ease: "power1.inOut" },
      onUpdate: () => {
        const progress = scroll.offset / (scroll.maxOffset || 1); // Calculate progress based on scroll offset
        tl3.current.progress(progress); // Update timeline progress
      },
    });
    tl3.current.to(subTitleRef.current, { x: "10000%" });
  }, []);

  useLayoutEffect(() => {
    tl.current = gsap.timeline({
      defaults: { duration: 2, ease: "power1.inOut" },
    });

    tl.current.to(
      girlRef.current,
      { x: "-30rem", ease: "power1.easeOut" },
      "-=3"
    );

    tl.current.to("#rocket--img", { x: "12rem" }, 2);
    tl.current.to("#rocket--cnt", { x: "-12rem" }, 2);

    tl.current.to("#id--one", { x: "12rem" }, "-=5");
    tl.current.to("#id--two", { x: "-12rem" }, "-=5");
  }, []);

  useLayoutEffect(() => {
    const timeline = new gsap.timeline();
    timeline.fromTo(
      "#title--1",
      {
        transform: "translateY(100%)",
      },
      {
        transform: "translateY(0)",
        stagger: 0.1,
        duration: 0.6,
      }
    );
    timeline.fromTo(
      "#title--2",
      {
        transform: "translateY(100%)",
      },
      {
        transform: "translateY(0)",
        stagger: 0.1,
        duration: 0.6,
      },
      "-=1"
    );
    timeline.fromTo(
      "#first--div",
      {
        x: "-200%",
      },
      { x: "0%", duration: 0.9 }
    );
  }, []);

  const letters = (str, id) => {
    return [...str].map((s, index) => (
      <span key={str + index} id={id}>
        {s}
      </span>
    ));
  };

  return (
    <>
      <Helmet>
        <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
      </Helmet>
      <div id="main--div">
        <Header />
        <div ref={cursorRef} className={classes.cursor}></div>
        <h1 ref={titleRef} className={classes.title}>
          {letters("Digital", "title--1")}
        </h1>
        <h1 ref={subTitleRef} className={classes.sub__title}>
          <span id="title--2">{letters("World", "title--2")}</span>
        </h1>

        <div id="first--div" className={`${classes.hero__para}`}>
          <h3>
            The only agency that covers your digital and creative needs
            together, efficiently!
          </h3>
        </div>
        <div className={classes.btn__home__wrapper}>
          <a className={`readmore ${classes.btn__home}`} href="/contact">
            <BsArrowUpRight />
            Partner With Us
          </a>
        </div>
        <div className={`flex ${classes.box} ${classes.box__1}`}>
          <div className={classes.inner_cnt}>
            <h2>DIVING DEEP into digital depths!</h2>
            <p>
              We, at DeepDive Media, design performance oriented marketing
              strategies to help Advertisers & Brands grow online. We curate the
              customised marketing strategies to help our clients, advertisers
              and brands expand their reach and profits online. With the family
              of 50+ teammates, we aim to with the agenda of providing a better
              ROI.
            </p>
            <a href="/contact" className="readmore">
              Read more
            </a>
          </div>
          <div ref={girlRef}>
            <img id="girl" src="/images/girl2.webp" />
          </div>
        </div>
        <div
          className={`row ${classes.vdosec}`}
          style={{
            position: "absolute",
            top: `232vh`,
            left: "50%",
            transform: "translateX(-50%)",
            width: "100vw",
          }}
        >
          {/* <video
            loop
            style={{ width: "100%" }}
            autoPlay
            muted
            src="/reel1.mp4"
          ></video> */}
          <ProjectCounter />
          <GoogleCompaign/>
          <OurClient />
          <Testimonial />
        </div>
        {/* <div id="rocket--img" className={classes.vdoimg}>
          <lottie-player
            src="https://assets8.lottiefiles.com/packages/lf20_rj4titti.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </div> */}
        {/* <div id="rocket--cnt" className={`${classes.box} ${classes.box__2}`}>
          <h2>Our Rocket-Powered Marketing !!!</h2>
          <p>
            Experience marketing that soars to new heights! Just like a rocket
            blazing through the sky, our strategies ignite your success, driving
            ROI higher and higher. Trust us to deliver results that truly take
            off!
          </p>
          <a className="readmore" href="/contact">
            Read more
          </a>
        </div> */}
        <SectionGlobe
          idOne={"id--one"}
          idTwo={"id--two"}
          styles={{
            position: "absolute",
            top: "398vh",
            left: "50%",
            transform: `translate(-50%,-50%)`,
          }}
        />
        <Services />
        <SectionProjects
          styles={{
            position: "absolute",
            top: "400vh",
            left: "50%",
            transform: `translate(-50%,-50%)`,
          }}
        />

        <SectionRequest
          styles={{
            position: "absolute",
            top: "730vh",
            left: "50%",
            transform: `translate(-50%,-50%)`,
          }}
        />
      </div>
    </>
  );
};

export default HomeContent;
