import React from "react";
import classes from "../styles/SectionGlobe.module.css";

const SectionGlobe = (props) => {
  return (
    <div
      id="section--globe"
      style={props.styles}
      className={`flex ${classes.section__globe}`}
    >
      <div className={`flex ${classes.inner_globe}`}>
        <div id={props.idOne} className={classes.cnt}>
          <div className={classes.inner__cnt}>
            <h2>DIVING DEEP into digital depths!</h2>
            <p>
              We, at DeepDive Media, design performance oriented marketing
              strategies to help Advertisers & Brands grow online.
            </p>
            <a className="readmore" href="/contact">Read more</a>
          </div>
        </div>
        <div id={props.idTwo} className={classes.video__sction}>
          <video loop autoPlay muted src="/videos/globe2.mp4"></video>
        </div>
      </div>
    </div>
  );
};

export default SectionGlobe;
