import React, { useEffect, useState, useRef } from 'react';
import Style from '../styles/Counter.module.css'


function ProjectCounter() {
    const [isVisible, setIsVisible] = useState(false);

    const counterRef = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          setIsVisible(entry.isIntersecting);
        },
        { threshold: 0.2 } // Adjust the threshold as needed
      );
  
      if (counterRef.current) {
        observer.observe(counterRef.current);
      }
  
      return () => {
        if (counterRef.current) {
          observer.unobserve(counterRef.current);
        }
      };
    }, []);
  
    useEffect(() => {
      if (isVisible) {
        const counters = document.querySelectorAll('.value');
        const speed = 100;
  
        counters.forEach((counter) => {
          const animate = () => {
            const value = +counter.getAttribute('count');
            const data = +counter.innerText;
  
            const time = value / speed;
            if (data < value) {
              counter.innerText = Math.ceil(data + time);
              setTimeout(animate, 100);
            } else {
              counter.innerText = value;
            }
          };
  
          animate();
        });
      }
    }, [isVisible]);


    return (
        <>


            <div ref={counterRef} className={`homeNumber ${Style.conterSec}`} >
                <div className={Style.cust_container}>
                    <div className={Style.cust_row}>
                        <div className={Style.CountrContent}>
                            <h2>Numbers That Define  Success!</h2>
                            <p>These numbers are more than just figures – they symbolise our relentless pursuit of excellence. With a passion for exceeding expectations, we continuously chase milestones, ensuring client satisfaction and delivering projects on time. We believe in turning aspirations into tangible results, and our numbers speak volumes!</p>
                        </div>
                        <div className={Style.conter_row}>
                            <div className={Style.countercol}>
                                <h2>EXPERIENCE</h2>
                                <h3 ><span className="value count" count="10">0</span ><span>+</span> </h3>
                            </div>
                            <div className={Style.countercol}>
                                <h2>TEAM SIZE</h2>
                                <h3 ><span className="value count" count="100">0</span ><span>+</span> </h3>
                            </div>
                            <div className={Style.countercol}>
                                <h2>Monthly Global Traffic</h2>
                                <h3 ><span className="value count" count="90">0</span ><span>M</span> </h3>
                            </div>
                            <div className={Style.countercol}>
                                <h2>Monthy Conversions</h2>
                                <h3 ><span className="value count" count="2.5">0</span ><span>M</span> </h3>
                            </div>
                            <div className={Style.countercol}>
                                <h2>Monthy GMV</h2>
                                <h3 ><span className="value count" count="2">0</span ><span>B</span> </h3>
                            </div>
                            <div className={Style.countercol}>
                                <h2>CLIENTS</h2>
                                <h3 ><span className="value count" count="5000">0</span ><span>+</span> </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProjectCounter;