import React, {  useEffect,useRef } from 'react';
import Style from '../styles/Servicepage.module.css'
function AllServices() {
    const columnRefs = useRef([]);
    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add('visible');
              } else {
                entry.target.classList.remove('visible');
              }
            });
          },
          { threshold: 0.1 }
        );
    
        columnRefs.current.forEach((columnRef) => {
          if (columnRef) {
            observer.observe(columnRef);
          }
        });
    
        return () => {
          observer.disconnect();
        };
      }, []);
    
      const handleColumnRef = (ref) => {
        if (ref && !columnRefs.current.includes(ref)) {
          columnRefs.current.push(ref);
        }
      };
    return (
        <>

            <section className={Style.allservices} id="allservices">
                <div className={Style.servrow}>
                    <div className={Style.servicelft}>
                        <div>
                            <img src="/images/service.png" alt="" />
                            <h2>We DIVE to FETCH the best digital marketing services !</h2>
                            <ul>
                                <li>- Seamless services that drive conversions.</li>
                                <li>- Innovative strategies, proven results.</li>
                                <li>- Smart techniques, quick implementations, & phenomenal results!</li>
                                </ul>
                        </div>
                    </div>
                    <div className={Style.servicert}>
                        <div className={Style.partServices} ref={handleColumnRef}>
                            <img src="/images/media.svg" alt="" />
                            <h3>Media Buying</h3>
                            <p>
                                We help you achieve hands-on results by conducting extensive
                                research and planning. We use our expertise to identify the
                                right channels for advertising and optimize your advertising
                                budget to increase your ROI
                            </p>
                        </div>
                        <div className={Style.partServices} ref={handleColumnRef}>
                            <img src="/images/email.svg" alt="" />
                            <h3>Email Marketing</h3>
                            <p>
                                We excel in strategic email marketing that delivers tangible
                                results. Our team of experts uses the latest tools and
                                technology to create customized email campaigns that resonate
                                with your target audience, increase your open rates, and drive
                                conversions.
                            </p>
                        </div>
                        <div className={Style.partServices} ref={handleColumnRef}>
                            <img src="/images/social.svg" alt="" />
                            <h3>Social Media Marketing</h3>
                            <p>
                                We understand that social media marketing is a powerful tool
                                for building your brand and driving sales. Our team uses
                                cutting-edge social media strategies to increase your brand
                                awareness, engage your audience, and drive conversions. We are
                                experienced in all major social media platforms, including
                                Facebook, Instagram, Twitter, and LinkedIn.
                            </p>
                        </div>
                        <div className={Style.partServices} ref={handleColumnRef}>
                            <img src="/images/influencer.svg" alt="" />
                            <h3>Influencers Marketing</h3>
                            <p>
                                We specialize in building fruitful collaborations with
                                influencers that align with your brand values and goals. Our
                                team works with influencers in your industry to create
                                innovative marketing campaigns that increase your brand
                                awareness and drive conversions. We handle everything from
                                identifying the right influencers to managing the campaign and
                                measuring its success.
                            </p>
                        </div>
                        <div className={Style.partServices} ref={handleColumnRef}>
                            <img src="/images/content.svg" alt="" />
                            <h3>Content Marketing</h3>
                            <p>
                                Our smart content strategies are tailored to your unique
                                marketing goals and objectives. We create high-quality content
                                that engages your audience and positions your brand as a
                                thought leader in your industry. Our content marketing
                                services include blog writing, social media content, video
                                production, and more.
                            </p>
                        </div>
                        <div className={Style.partServices} ref={handleColumnRef}>
                            <img src="/images/video.svg" alt="" />
                            <h3>Video and Branding</h3>
                            <p>
                                We believe that video is one of the most effective ways to
                                build your brand and engage your audience. Our team of video
                                production experts creates high-quality videos that capture
                                your brand's essence and convey your message effectively. We
                                help you leverage the power of video to tell your story,
                                showcase your products or services, and build your brand
                                identity.
                            </p>
                        </div>
                        <div className={Style.partServices} ref={handleColumnRef}>
                            <img src="/images/Lead.svg" alt="" />
                            <h3>Lead Generation</h3>
                            <p>We employ advanced strategies and data-driven techniques to identify and capture high-quality leads that are most likely to convert. We deliver a consistent stream of qualified leads, enabling you to boost your revenue.</p>
                        </div>
                        <div className={Style.partServices} ref={handleColumnRef}>
                            <img src="/images/Gaming.svg" alt="" />
                            <h3>Gaming</h3>
                            <p>Our team of talented developers, designers, and creative minds collaborate to bring captivating gaming experiences to life. With a deep understanding of the gaming industry and its ever-evolving landscape, we strive to create games that leave a lasting impact on players. We embrace the latest technologies and trends, ensuring that our games are at the forefront of the industry.</p>
                        </div>
                        <div className={Style.partServices} ref={handleColumnRef}>
                             <a href="/googleads">
                                <img src="/images/Protection.svg" alt="" />
                                <h3>Google Ad protection</h3>
                                <p>Click fraud poses a significant threat to the success of your Google Ads campaigns. This deceptive practice involves fraudulent clicks on your ads, resulting in wasted advertising budget and skewed campaign performance. However, with our cutting-edge techniques, you can shield your campaigns from click frauds.</p>
                                </a>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default AllServices;