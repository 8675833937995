import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import AboutContent from "../components/AboutContent";
import Helmet from "react-helmet";
const About = () => {

  return (
    <>
         <Helmet>
                <title>About-DDM</title>
      </Helmet>
      <section id="main-container">

    <AboutContent />
      </section>
    </>
  );
};

export default About;
