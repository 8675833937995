import React from "react";
import classes from "../styles/Footer.module.css";
import { BsArrowUpRight } from "react-icons/bs";

import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
} from "react-icons/ai";

const Footer = (props) => {
  return (
    <div style={props.styles} className={` ${classes.footer}`}>
      <div className={classes.deskHead}>
        <h2 className={classes.footer__heading}>You can find us</h2>
      </div>
      <div className={`flex ${classes.inner__footer}`}>
        <div className={classes.first__div}>
          <img src="/images/logo.svg" />
        </div>
        <div className={classes.mobhead}>
             <h2 className={classes.footer__heading}>You can find us</h2>
          </div>
        <div className={`${classes.info} ${classes.info__1}`}>
          <h5>
            <strong>Delhi</strong>
          </h5>
          <p>+919875998191</p>
          <p>info@deepdivemedia.in</p>
          <p className={classes.addrss}>
            5th Floor, 500, ITL Twin Tower, Netaji Subhash Place, Pitampura, North West Delhi, Delhi, 110034
          </p>
        </div>
        <div className={`${classes.info} ${classes.info__1}`}>
          <h5>
            <strong>Noida</strong>
          </h5>
          <p>+919910975707</p>
          <p>info@deepdivemedia.in</p>
          <p className={classes.addrss}>
            9th Floor, Office No. 26, THE I-THUM Tower, Tower-B, NOIDA, Gautam Buddha Nagar, Uttar Pradesh, 201301
          </p>
        </div>
        <div className={`${classes.info} ${classes.info__1}`}>
          <h5>
            <strong>Mohali</strong>
          </h5>
          <p>+919875998195</p>
          <p>info@deepdivemedia.in</p>
          <p className={classes.addrss}>
            4th floor, Orbit Vision Tower, D 256, Phase 8-A, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160062
          </p>
        </div>
      </div>
    
      <div className={`flex ${classes.footer__last} `}>
        <h3>2023 - © Deepdive Media</h3>
        <div className={classes.social__icons}>
          <a href="https://www.facebook.com/deepdivemedia.in/"><AiFillFacebook /></a>
          <a href="https://www.instagram.com/_deepdivemedia/"><AiFillInstagram /></a>
          <a href="https://in.linkedin.com/company/deepdive-media"><AiFillLinkedin /></a>
        </div>

        <div className={classes.links__wrapper}>
          <ul>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
            <li>
              <a href="/workwithus">Work With Us </a>
            </li>
            <li>
              <a href="/privacypolicy">Privacy Policy </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
