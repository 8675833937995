import React, { useRef, useEffect } from 'react';
import Style from '../styles/About.module.css'
import { MdOutlineKeyboardDoubleArrowDown } from 'react-icons/md';
import gsap from 'gsap';

function AboutBanner() {
  const columnRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible_left');
          } else {
            entry.target.classList.remove('visible_left');
          }
        });
      },
      { threshold: 0.1 }
    );

    columnRefs.current.forEach((columnRef) => {
      if (columnRef) {
        observer.observe(columnRef);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);



  const handleColumnRef = (ref) => {
    if (ref && !columnRefs.current.includes(ref)) {
      columnRefs.current.push(ref);
    }
  };


  useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible');
            } else {
              entry.target.classList.remove('visible');
            }
          });
        },
        { threshold: 0.1 }
      );
  
      columnRefs.current.forEach((columnRef) => {
        if (columnRef) {
          observer.observe(columnRef);
        }
      });
  
      return () => {
        observer.disconnect();
      };
    }, []);
  
    const handlecolumn = (ref) => {
      if (ref && !columnRefs.current.includes(ref)) {
        columnRefs.current.push(ref);
      }
    };

  return (
    <>
      <section className={Style.aboutbanner}>
        {/* <div className={Style.overlay}></div>
                <div className={Style.bannercont}>
                    <h1>About Us</h1>
                    <p>ABOUT DEEPDIVE MEDIA</p>
                </div> */}
        <div className={Style.downArrow}>
          <a href="#about" className={Style.arrow_down}> <MdOutlineKeyboardDoubleArrowDown /> </a>
        </div>
        <video
          loop
          style={{ width: "100%" }}
          autoPlay
          muted
          src="/videos/about.mp4"
        ></video>
      </section>
      <section className={Style.aboutquote}>
        <h2 ref={handlecolumn}>About Us</h2>
        <p ref={handlecolumn}>Connecting dots and recognising peculiar patterns out of ordinary has been our strong suit. When it comes to using technology in the wittiest way possible, our strategies are unbeatable. With a perfect blend of business and technology, we at DeepDive Media start our day with planning and end with seamless and successful execution.</p>

      </section>
      <section className={Style.aboutcomp} id="about">
        <div>
        
          <div className={`${Style.aboutrow}`}>

            <div className={`${Style.left_content}`} ref={handleColumnRef}>
            <h2>Competition And Creative Runs Hard In His Veins </h2>
             
              <p>"For an organisation to lead the competitors, it is very important to Chase, Upgrade and Sustain. Chase the new challenges, Upgrade with the latest technology, and Sustain the calm. I try to push all my teammates in achieving so. Their zeal, in turn, pushes me to do the same. </p>
              <p>For me, DeepDive Media is merely not an organisation where everyone works behind the screens. It is an emotion that fulfils dreams of all those who are associated with it. For us, as a team, each and every effort is worthy of being ‘on screen."</p>
        <p class><b>- Arshdeep Singh</b> <br/> <b>Founder & CEO</b> </p>
           
            </div>
            <div className={`${Style.right_content}`} ref={handleColumnRef}>
              <img src="../images/arsh.png" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutBanner;