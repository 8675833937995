import React, { Component } from 'react';
import Style from '../styles/Work.module.css'
function WorkBanner() {
    return ( 
        <>
            <section className={Style.work_banner}>
                <div className={Style.cust_container}>
                    <div className={Style.cust_row}>
                        <div className={Style.left_content}>
                            <h1>Work With US</h1>
                            <a className="readmore" href="/contact">Contact Us</a>
                        </div>
                        <div className={`${Style.rt_cont} rt_img_cont`}>
                            <img src="/images/work.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </>
     );
}

export default WorkBanner;