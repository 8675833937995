import React from "react";

import ContactContent from "../components/Contact";
import Helmet from "react-helmet";
const Contact = () => {

  return (
    <>
          <Helmet>
                <title>Contact-DDM</title>
            </Helmet>
       <ContactContent  />
    </>
  );
};

export default Contact;
