import React, { Component } from 'react';
import { IoIosWarning } from "react-icons/io";
function DialogBox() {
    return ( 
        <>
            <div className='dialogbox'>
                <IoIosWarning/>
                <h2>Please Click ENABLE for <span>"Use hardware acceleration when available"</span> from the browser settings!!!</h2>
                <p>Note* : Only then you will be able to view the Website</p>
            </div>
        </>
     );
}

export default DialogBox;