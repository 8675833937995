import React, { Component } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Style from '../styles/Testimonial.module.css'
import { FaAngleRight, FaQuoteRight, FaAngleLeft } from 'react-icons/fa'
import { AiFillStar } from 'react-icons/ai'
function Testimonial(props) {

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        arrows: true,
        dots:true,
        prevArrow:<FaAngleLeft />,
        nextArrow: <FaAngleRight />,
        responsive: [
          
            {
              breakpoint: 767,
              settings: {
                arrows:false,
              },
            },
          ],
    };
    return (
        <>
            <section style={props.styles}  className={Style.TestimonialSec}>
                <div className={Style.cust_container}>
                    <div className={Style.cust_row}>
                        <div className={Style.test_head}>
                            <h6>WHAT CLIENTS SAY</h6>
                            <h2>Testimonials</h2>
                        </div>
                        <Slider {...settings} className="testmonialslider">
                            <div>
                                <div className={Style.sliderrow}>
                                    <div className={Style.slider_left}>
                                        <div>
                                        <div className={Style.client_img}>
                                            <img src="/images/Pat-Pat.png" alt=""  />
                                        </div>
                                        <h6>BRAND</h6>
                                            <h3>PatPat</h3>
                                        </div>
                                    </div>
                                    <div className={Style.slider_right}>
                                        <FaQuoteRight />
                                        <div>
                                            <div className={Style.slider_head}>
                                                <div>
                                                    <h2>Jenny Chen</h2>
                                                    <h4>Affiliate Marketing Manager</h4>
                                                </div>
                                                <div className={Style.star}>
                                                    <AiFillStar />
                                                    <AiFillStar />
                                                    <AiFillStar />
                                                    <AiFillStar />
                                                    <AiFillStar />
                                                </div>
                                            </div>
                                            <p>At PatPat, We Always Find Many Coupons Website Partners To Share Our Specific Discount. Working With DeepDive Media, We Share Many Coupon Codes To Our Customers, We Gain More New Customers And Better Performance Together. Happy To Keep Long- Term Collaboration With DDM.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div className={Style.sliderrow}>
                                    <div className={Style.slider_left}>
                                        <div>
                                        <div className={Style.client_img}>
                                            <img src="/images/Aosom.png" alt=""  />
                                        </div>
                                        <h6>BRAND</h6>
                                            <h3>Aosom.c</h3>
                                        </div>
                                    </div>
                                    <div className={Style.slider_right}>
                                        <FaQuoteRight />
                                        <div>
                                            <div className={Style.slider_head}>
                                                <div>
                                                    <h2>Scarlett</h2>
                                                    <h4>Affiliate Marketing Team</h4>
                                                </div>
                                                <div className={Style.star}>
                                                    <AiFillStar />
                                                    <AiFillStar />
                                                    <AiFillStar />
                                                    <AiFillStar />
                                                    <AiFillStar />
                                                </div>
                                            </div>
                                            <p>Thanks For Helping Introduce Our Website And Bringing Us More Sales Good Job! Appreciate Your Efforts To Drive Traffic. It's A Great Pleasure Working With You.<span className={Style.hide}>Thanks For Helping Introduce Our Website And Bringing Us More Sales Good Job! Appreciate Your Efforts To Drive Traffic. It's A Great Pleasure Working With You.</span></p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div className={Style.sliderrow}>
                                    <div className={Style.slider_left}>
                                        <div>
                                        <div className={Style.client_img}>
                                            <img src="/images/Check-MyHealth.png" alt=""  />
                                        </div>
                                        <h6>BRAND</h6>
                                            <h3>Check My Body Health</h3>
                                        </div>
                                    </div>
                                    <div className={Style.slider_right}>
                                        <FaQuoteRight />
                                        <div>
                                            <div className={Style.slider_head}>
                                                <div>
                                                    <h2>James Wilson</h2>
                                                    <h4>Affiliate Marketing Manager</h4>
                                                </div>
                                                <div className={Style.star}>
                                                    <AiFillStar />
                                                    <AiFillStar />
                                                    <AiFillStar />
                                                    <AiFillStar />
                                                    <AiFillStar />
                                                </div>
                                            </div>
                                            <p>Working with DeepDive Media has been a fantastically fruitful partnership. The team has been responsive to each request- for onboarding, optimisation and any queries we had, and have also been a consistent revenue driver for our business. They are everything we look for in an affiliate partner.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                           
                         
                        </Slider>
                    </div>

                </div>
            </section>

        </>
    );
}

export default Testimonial;