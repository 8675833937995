import React, { Component } from 'react';
import Footer from '../components/Footer';
import GoogleAdsView from '../components/GoogleAdsView';
import Header from '../components/Header';

function GoogleAds() {
    return (
        <>
            <Header />
            <GoogleAdsView/>
            <div className="footer_container">
                <Footer />
            </div>
        </>
    );
}

export default GoogleAds;