import React from "react";
import ProfileCard from "../components/ProfileCard";

const UserCard = (props) => {
  return (
    <div className="section--cards center">
      <ProfileCard name={props.name} />
    </div>
  );
};

export default UserCard;
