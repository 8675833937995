import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import ContactDetail from "./ContactDetail";

const ContactContent = (props) => {
  return (
    <>
      <Header />
      <ContactDetail
      />
      <div className="footer_container">
        <Footer/>
      </div>

    </>
  );
};

export default ContactContent;
