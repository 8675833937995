import React, { useEffect, useRef, useState  } from 'react';
import Style from '../styles/Client.module.css'
function OurCLient() {
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
        const sectionObserver = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
            }
          });
        }, { threshold: 0.5 }); // Adjust this threshold value as needed
    
        if (sectionRef.current) {
          sectionObserver.observe(sectionRef.current);
        }
    
        return () => {
          if (sectionRef.current) {
            sectionObserver.unobserve(sectionRef.current);
          }
        };
      }, []);
    
    return ( 
        <>
            <section  ref={sectionRef} className={`section ${isVisible ? Style.show : ''} ${Style.cleintSec}`}>
                <div className={Style.cust_container}>
                    <div className={Style.cust_row}>
                        <div className={Style.left_col}>
                            <h2>Our Partners In <br/> Progress</h2>
                            <p>We are proud to be associated with an exclusive network of industry leaders. Our commitment to delivering exceptional results, fostering strong relationships, and staying at the forefront of innovation sets us apart. We prioritise building long-lasting relationships based on trust, reliability, and a deep understanding of their business needs.</p>
                        </div>
                        <div className={Style.right_col}>
                            <div>
                            <img src="/images/client1.svg" alt="" className={Style.img1} />
                            <img src="/images/client2.svg" alt="" className={Style.img2} />
                            <img src="/images/client3.svg" alt="" className={Style.img3} />
                            <img src="/images/client4.svg" alt="" className={Style.img4} />
                            <img src="/images/client5.svg" alt="" className={Style.img5} />
                            <img src="/images/client6.svg" alt="" className={Style.img6} />
                            <img src="/images/client7.svg" alt="" className={Style.img7} />
                            <img src="/images/client8.svg" alt="" className={Style.img8} />
                            </div>
                          
                        </div>
                    </div>
                </div>
            </section>
        </>
     );
}

export default OurCLient;