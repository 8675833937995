import React,{useRef, useEffect } from "react";
import classes from "../styles/SectionRequest.module.css";
import styles from "../styles/Home.module.css";
import { BsArrowUpRight } from "react-icons/bs";
import Footer from "./Footer";

const SectionRequest = (props) => {
  const columnRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          columnRef.current.classList.add("visibletxt");
        } else {
          columnRef.current.classList.remove("visibletxt");
        }
      });
    });

    observer.observe(columnRef.current);

    return () => {
      observer.unobserve(columnRef.current);
    };
  }, []);

  
  return (
    <div
      style={props.styles}
      className={`${classes.section__req}`}
    >
      <div className="flex flex--1">
      <div className={classes.lft} ref={columnRef}>
        <p>Leave a request</p>
        <h3>
        We'd love to initiate a conversation! Feel free to share your brief with
          us.
        </h3>
      </div>
      <div className={classes.rght}>
        <div
          className={`${styles.btn__home__wrapper} ${styles.btn__home__wrapper__2}`}
        >
          <a className={`readmore ${classes.reqbtn} ${styles.btn__home}`} href="/contact">
            <BsArrowUpRight />
            Partner With Us
          </a>
        </div>
        </div>
      </div>
      <div className={classes.footerspace}>
        <Footer/>
      </div>
    </div>
  );
};

export default SectionRequest;
