import React, { useEffect,useRef } from 'react';
import Style from '../styles/About.module.css'

function AboutContact() {
    const columnRefs = useRef([]);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('contentvisible');
            } else {
              entry.target.classList.remove('contentvisible');
            }
          });
        },
        { threshold: 0.1 }
      );
  
      columnRefs.current.forEach((columnRef) => {
        if (columnRef) {
          observer.observe(columnRef);
        }
      });
  
      return () => {
        observer.disconnect();
      };
    }, []);
  
    const handleColumnRef = (ref) => {
      if (ref && !columnRefs.current.includes(ref)) {
        columnRefs.current.push(ref);
      }
    };

    
    return (
        <>
            <section className={Style.ctasec}>
                <div className={`${Style.aboutrow}`}>
                    <div className={Style.ctaleft} >
                        <h2 ref={handleColumnRef} className={Style.lets}>let's</h2>
                        <h2 ref={handleColumnRef} className={`ctahead ${Style.talk}`}>talk</h2>
                    </div>
                    <div className={Style.contactContent}>
                        <p>Unlock your digital marketing potential today! Contact us today and let us drive your online success.</p>
                        <a className="readmore" href="/contact">Read more</a>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutContact;