import React, { Component } from 'react';
import { AiFillStar } from 'react-icons/ai';
import Style from '../styles/Google.module.css'
function GoogleAdsView() {
    return (
        <>
            <section className={Style.adsbanner}>
                <div className={Style.cust_container}>
                    <div className={Style.cust_row}>
                        <div className={Style.banner_left_col}>
                            <h1>Eliminate Click Fraud in Your <span>Google Ads</span> Campaigns.</h1>
                            <p>Combat click fraud in your Google Ads campaigns, effectively block bots, and maximize your campaigns' ROAS.</p>
                            <div className={`${Style.btn_area} ${Style.dis_flex}`}>
                                <a href="/contact" className='readmore'>Start free trial</a>
                                <div className={Style.rating}>
                                    <div>
                                        <AiFillStar />
                                        <AiFillStar />
                                        <AiFillStar />
                                        <AiFillStar />
                                        <AiFillStar />
                                    </div>
                                    <p>Based on 1000+ reviews</p>
                                </div>
                            </div>
                            <img src="/images/Google-Api-1.svg" alt="" className={Style.googleapi} />

                        </div>
                        <div className={Style.banner_right_col}>
                            <img src="/images/bannerads.svg" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            
            <section className={Style.protectedAds}>
                <div className={Style.cust_container}>
                    <h2>Safeguard Your Google Ads!</h2>
                    <div className={Style.cust_row}>
                        <div className={Style.cus_col}>
                            <img src="/images/protected1.svg" alt="" />
                            <h3>Display ads</h3>
                        </div>
                        <div className={Style.cus_col}>
                            <img src="/images/protected2.svg" alt="" />
                            <h3>Shopping ads</h3>
                        </div>
                        <div className={Style.cus_col}>
                            <img src="/images/protected3.svg" alt="" />
                            <h3>Search campaign</h3>
                        </div>
                        <div className={Style.cus_col}>
                            <img src="/images/protected4.svg" alt="" />
                            <h3>GDN</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className={Style.monitoringSec}>
                <div className={Style.cust_container}>
                    <h2>Put an end to all fraudulent clicks with our automated solution!</h2>
                    <div className={Style.cust_row}>
                        <div className={Style.left_col}>
                            <div className={`${Style.dis_flex}`}>
                                <img src="/images/Monitor.svg" alt="" />
                                <div>
                                    <h3>Real-Time Traffic Monitoring</h3>
                                    <p>With our advanced AI filters, we continuously analyze each click on your Google Ads in real time. Say goodbye to bots, click farms, and any other invalid sources of traffic.</p>
                                </div>
                            </div>
                            <div className={`${Style.dis_flex}`}>
                                <img src="/images/Block.svg" alt="" />
                                <div>
                                    <h3>Selective Traffic Blocking</h3>
                                    <p>DeepDive Media swiftly identifies fraudulent IPs and promptly adds them to your Google Ads exclusion list within a mere 3 seconds. It blocks bad traffic, keeping genuine customer engagement.</p>
                                </div>
                            </div>
                        </div>
                        <div className={Style.right_col}>
                            <img src="/images/click.svg" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className={Style.traffic}>
                <div className={Style.cust_container}>
                    <h2>Secure Ads in Minutes</h2>
                    <div className={Style.cust_row}>
                        <div className={Style.cust_col3}>
                            <img src="/images/securead1.svg" alt="" />
                            <h3>Choose Your Google Ads Email</h3>
                        </div>
                        <div className={Style.cust_col3}>
                            <img src="/images/securead2.svg" alt="" />
                            <h3>Link your Google Ads account</h3>
                        </div>
                        <div className={Style.cust_col3}>
                            <img src="/images/securead3.svg" alt="" />
                            <h3>Instantly prevent invalid IP addresses from viewing your ads</h3>
                        </div>
                    </div>
                    <div className={Style.textcenter}>
                        <a href="/contact" className='readmore'>Start Free Trial</a>
                    </div>
                </div>
            </section>
            <section className={Style.traffic}>
                <div className={Style.cust_container}>
                    <h2>Gain unparalleled visibility into your Google Ads traffic</h2>
                    <div className={Style.cust_row}>
                        <div className={Style.left_col}>
                            <h3>Observe Visitor Engagement on Your Site</h3>
                            <p>By capturing session recordings of every click, you gain valuable insights into how both visitors and bots interact with your website.</p>
                        </div>
                        <div className={` ${Style.order} ${Style.right_col}`}>
                            <img src="/images/traffic1.svg" alt="" />
                        </div>
                        <div className={`${Style.order1} ${Style.right_col} ${Style.desk}`}>
                            <img src="/images/traffic2.svg" alt="" />
                        </div>
                        <div className={Style.left_col}>
                            <h3>Track the Origin of Your Clicks with Precision</h3>
                            <p>Leveraging precise ISP and location tracking, unveil the exact sources of your traffic. Our advanced filters delve deep into each click, revealing the authentic location details, including country, city, and even street address.</p>
                        </div>
                        <div className={`${Style.order1} ${Style.right_col} ${Style.mob}`}>
                            <img src="/images/traffic2.svg" alt="" />
                        </div>
                        <div className={Style.left_col}>
                            <h3>Complete Transparency in Click Reporting</h3>
                            <p>Attain absolute control over your ad clicks. Receive comprehensive insights that highlight fraudulent visitors, out-of-geo clicks, and non-converting ad traffic. This level of detail empowers you to optimize your campaigns effectively and drive better results.</p>
                        </div>
                        <div className={Style.right_col}>
                            <img src="/images/traffic3.svg" alt="" />
                        </div>
                        <div className={Style.textcenter}>
                            <a href="/contact" className='readmore'>Start Free Trial</a>
                        </div>
                    </div>
                </div>
            </section>

            <section className={Style.protection_sec_det}>
                <div className={Style.cust_container}>
                    <h2>Desire For Extraordinary Protection!</h2>
                    <div className={Style.scrollingDiv}>
                        <div className={`${Style.prot_det} ${Style.cust_row}`}>
                            <div className={Style.table_col}>

                            </div>
                            <div className={`${Style.centerText} ${Style.table_col}`}>
                                <h4>Default Google Ads protection</h4>
                            </div>
                            <div className={`${Style.centerText} ${Style.table_col}`}>
                                <img src="/images/googlecc.png" alt="" />
                            </div>

                            <div className={Style.table_col}>
                                <h4>Real-time blocking <img src="/images/Tooltip-question-mark.svg" alt="" /></h4>
                            </div>
                            <div className={`${Style.centerText} ${Style.table_col}`}>
                                <h4><img src="/images/cross.svg" alt="" /></h4>
                            </div>
                            <div className={`${Style.centerText} ${Style.table_col}`}>
                                <h4>Under 3 Seconds</h4>
                            </div>

                            <div className={Style.table_col}>
                                <h4>Exclude VPN traffic <img src="/images/Tooltip-question-mark.svg" alt="" /></h4>
                            </div>
                            <div className={`${Style.centerText} ${Style.table_col}`}>
                                <h4><img src="/images/cross.svg" alt="" /></h4>
                            </div>
                            <div className={`${Style.centerText} ${Style.table_col}`}>
                                <img src="/images/checkmark.svg" alt="" />
                            </div>
                            <div className={Style.table_col}>
                                <h4>Click detection threshold <img src="/images/Tooltip-question-mark.svg" alt="" /></h4>
                            </div>
                            <div className={`${Style.centerText} ${Style.table_col}`}>
                                <h4><img src="/images/cross.svg" alt="" /></h4>
                            </div>
                            <div className={`${Style.centerText} ${Style.table_col}`}>
                                <h4>30+ Data Points Per Click</h4>
                            </div>
                            <div className={Style.table_col}>
                                <h4>Industry-based data  <img src="/images/Tooltip-question-mark.svg" alt="" /></h4>
                            </div>
                            <div className={`${Style.centerText} ${Style.table_col}`}>
                                <h4><img src="/images/cross.svg" alt="" /></h4>
                            </div>
                            <div className={`${Style.centerText} ${Style.table_col}`}>
                                <img src="/images/checkmark.svg" alt="" />
                            </div>

                            <div className={Style.table_col}>
                                <h4>Visitors Analytics  <img src="/images/Tooltip-question-mark.svg" alt="" /></h4>
                            </div>
                            <div className={`${Style.centerText} ${Style.table_col}`}>
                                <h4><img src="/images/cross.svg" alt="" /></h4>
                            </div>
                            <div className={`${Style.centerText} ${Style.table_col}`}>
                                <img src="/images/checkmark.svg" alt="" />
                            </div>

                            <div className={Style.table_col}>
                                <h4>Fraudulent behavior session recordings <img src="/images/Tooltip-question-mark.svg" alt="" /></h4>
                            </div>
                            <div className={`${Style.centerText} ${Style.table_col}`}>
                                <h4><img src="/images/cross.svg" alt="" /></h4>
                            </div>
                            <div className={`${Style.centerText} ${Style.table_col}`}>
                                <h4>All Sessions recorded</h4>
                            </div>

                        </div>
                    </div>

                </div>
            </section>

            <section className={Style.gdpr_sec_det}>
                <div className={Style.cust_container}>
                    <div className={` ${Style.cust_row}`}>
                        <div className={Style.gdpr_left}>
                            <h2>GDPR & CCPA Compliance</h2>
                            <p>At DeepDive Media, safeguarding your data and respecting your privacy are our top priorities. We are fully committed to complying with regulations.</p>
                        </div>
                        <div className={Style.gdpr_right}>
                            <div className={Style.dis_flex}>
                                <div>
                                    <img src="/images/gdpr.png" alt="" />
                                    <h3>GDPR</h3>
                                </div>
                                <div>
                                    <img src="/images/ccpa.svg" alt="" />
                                    <h3>CCPA</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={Style.platform_sec}>
                <div className={Style.cust_container}>
                    <h2>Seamless Integration with Any Platform</h2>
                    <p>DeepDive Media seamlessly collaborates with any platform you choose.<br /> Whether it's your own custom site or a preferred third-party platform.</p>
                    <div className={` ${Style.cust_row}`}>
                        <img src="/images/platforms.png" alt="" />
                    </div>
                </div>
            </section>
            <section className={Style.ctasec}>
                <div className={`${Style.textcenter} ${Style.cust_container}`}>
                    <h2>DeepDive Media: Protecting Over 20,000 Campaigns globally!</h2>
                    <a href="/contact" className='readmore'>Start free trial</a>
                </div>

            </section>


        </>
    );
}

export default GoogleAdsView;