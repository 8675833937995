import React, { Component, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Style from '../styles/Work.module.css'
function OpeningJob() {
    const [isjobOpen, setOpen] = useState(false);
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        arrows: true,
        margin: "10px",
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            },

        ]
    };
    return (
        <>

            <section className={Style.openingjob}>
                <div className={Style.cust_container}>
                    <h2>JOB OPENINGS</h2>
                    {isjobOpen ? <Slider {...settings} className="jobslider">
                        <div className={Style.jobCard}>
                            <div>
                                <h2>Marketing Executive</h2>
                                <h3>Email Marketing Manager</h3>
                                <p> Min 2 years of experience</p>
                                <a href='#'>Apply Now</a>
                                <div className={Style.location}>Phase 8A, Mohali</div>
                            </div>
                        </div>
                        <div className={Style.jobCard}>
                            <div>
                                <h2>Marketing Executive</h2>
                                <h3>Email Marketing Manager</h3>
                                <p> Min 2 years of experience</p>
                                <a href='#'>Apply Now</a>
                                <div className={Style.location}>Phase 8A, Mohali</div>
                            </div>
                        </div>
                        <div className={Style.jobCard}>
                            <div>
                                <h2>Marketing Executive</h2>
                                <h3>Email Marketing Manager</h3>
                                <p> Min 2 years of experience</p>
                                <a href='#'>Apply Now</a>
                                <div className={Style.location}>Phase 8A, Mohali</div>
                            </div>
                        </div>
                        <div className={Style.jobCard}>
                            <div>
                                <h2>Marketing Executive</h2>
                                <h3>Email Marketing Manager</h3>
                                <p> Min 2 years of experience</p>
                                <a href='#'>Apply Now</a>
                                <div className={Style.location}>Phase 8A, Mohali</div>
                            </div>
                        </div>

                    </Slider> : <div className={`${Style.noJob} ${Style.jobCard}`}>
                        <div>
                            <h3>Currently there isn't any opening but you can stay in touch!</h3>
                        </div>
                    </div>}
                </div>
            </section>

        </>
    );
}

export default OpeningJob;