import React, { Component } from 'react';
import AllServices from '../components/AllServices';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ServiceBanner from '../components/ServiceBanner';
import AboutContact from '../components/AboutContact'
import Helmet from 'react-helmet';
function Service() {
    return ( 
        <>
            <Helmet>
                <title>Service-DDM</title>
            </Helmet>
            <Header />
            <ServiceBanner />
            <AllServices />
            <AboutContact />
            <div className="footer_container">
                <Footer/>
            </div>
        </>
     );
}

export default Service;