import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import classes1 from "../styles/Services.module.css";
import { Helmet } from "react-helmet";
import { useFrame } from "@react-three/fiber";
import { useScroll } from "@react-three/drei";
import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";
gsap.registerPlugin(ScrollTrigger);
const Services = (props) => {
  const scroll = useScroll();

  const tl = useRef();
  const titleRef = useRef();
  const subTitleRef = useRef();
  const sectionRef = useRef(null);
  useFrame((state, delta) => {
    tl.current.seek(scroll.offset * tl.current.duration());
  });

  const columnRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    columnRefs.current.forEach((columnRef) => {
      if (columnRef) {
        observer.observe(columnRef);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleColumnRef = (ref) => {
    if (ref && !columnRefs.current.includes(ref)) {
      columnRefs.current.push(ref);
    }
  };

  useLayoutEffect(() => {
    tl.current = gsap.timeline({
      defaults: { duration: 2, ease: "power1.inOut" },
    });

    tl.current.to("#col1", { x: "4rem" }, 0.4);
    tl.current.to("#col2", { x: "-12rem" }, 0.4);
  }, []);

  return (
    <>
      <Helmet>
        <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
      </Helmet>

      <section
        ref={sectionRef}
        className={`section--service ${classes1.section__service} `}
      >
        <div className={classes1.inner_row}>
          <div className={`${classes1.inner_content} left_side`} id="col1">
            <div>
              <img
                src="/images/standinggirl1.webp"
                alt=""
                className={classes1.girlsimg}
              />
              <h2>Buit for the future of b2b marketing:</h2>
              <p>
              We transform B2B marketing complexities into seamless digital solutions with expertise!
              </p>
            </div>
          </div>
          <div className={classes1.all_services} id="col2">
            <div className="service_col" ref={handleColumnRef}>
              <img src="/images/media.svg" alt="" />
              <h3>Media Buying</h3>
              <p>
                Get hands on results with research, planning, and rising ROI
                with us!.
              </p>
            </div>
            <div className="service_col" ref={handleColumnRef}>
              <img src="/images/email.svg" alt="" />
              <h3>Email Marketing</h3>
              <p>
                Strategic Email marketing isn’t everyone’s forte. For us, it is
                indeed!
              </p>
            </div>
            <div className="service_col" ref={handleColumnRef}>
              <img src="/images/social.svg" alt="" />
              <h3>Social Media Marketing</h3>
              <p>
                Social media marketing is the secret of success, and we aim only
                at such success.
              </p>
            </div>
            <div className="service_col" ref={handleColumnRef}>
              <img src="/images/influencer.svg" alt="" />
              <h3>Influencers Marketing</h3>
              <p>
                Fruitful collabs, best endorsements, and innovative marketing is
                our cup of tea!
              </p>
            </div>
            <div className="service_col" ref={handleColumnRef}>
              <img src="/images/content.svg" alt="" />
              <h3>Content Marketing</h3>
              <p>
                Smart content strategies for best marketing solutions is our
                expertise.
              </p>
            </div>
            <div className="service_col" ref={handleColumnRef}>
              <img src="/images/video.svg" alt="" />
              <h3>Video and Branding</h3>
              <p>
                Capturing the best marketing and branding is something we never
                run away from.
              </p>
            </div>
            <div className="service_col" ref={handleColumnRef}>
              <img src="/images/Gaming.svg" alt="" />
              <h3>Gaming</h3>
              <p>
                Our talented team of developers, designers, and creative minds
                bring gaming vision to life via best technology.
              </p>
            </div>
            <div className="service_col" ref={handleColumnRef}>
              <img src="/images/video.svg" alt="" />
              <h3>Lead Gen</h3>
              <p>
                Unlock a stream of high-quality leads and maximise your
                conversion rates with our expert lead generation services.
              </p>
            </div>
            <div className="service_col" ref={handleColumnRef}>
              <a href="/googleads">
              <img src="/images/Protection.svg" alt="" />
              <h3>Google Ad protection</h3>
              <p>
              With our strategies, we help you tackle click fraud head-on, safeguarding your advertising investments and driving meaningful results.
                </p>
                </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
