import React, { useLayoutEffect, useRef, useEffect } from "react";
import Header from "./Header";
import classes from "../styles/Home.module.css";
import classes1 from "../styles/Services.module.css";
import { BsArrowUpRight } from "react-icons/bs";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Letter from "./Letter";
import gsap from "gsap";
import Footer from "./Footer";
import Style from "../styles/Mobilehome.module.css";
import { Helmet } from "react-helmet";
import Services from "./Services";
import { Canvas } from "@react-three/fiber";
import { ScrollControls } from "@react-three/drei";
import ProjectCounter from "./ProjectCounter";
import OurCLient from "./OurClient";
import Testimonial from "./Testimonial";
import GoogleCompaign from "./GoogleCompaign";

gsap.registerPlugin(ScrollTrigger);

function MobileHome() {
  const titleRef = useRef();
  const subTitleRef = useRef();
  const columnRefs = useRef([]);

  useEffect(() => {
    gsap.fromTo(
      "#heading--hero",
      { opacity: 0 },
      { opacity: 1, duration: 2, stagger: 0.3 }
    );
  },[])
  useLayoutEffect(() => {
    const timeline = new gsap.timeline();
    timeline.fromTo(
      "#title--1",
      {
        transform: "translateY(100%)",
      },
      {
        transform: "translateY(0)",
        stagger: 0.1,
        duration: 0.6,
      }
    );
    timeline.fromTo(
      "#title--2",
      {
        transform: "translateY(100%)",
      },
      {
        transform: "translateY(0)",
        stagger: 0.1,
        duration: 0.6,
      },
      "-=1"
    );
    timeline.fromTo(
      "#first--div",
      {
        x: "-200%",
      },
      { x: "0%", duration: 0.9 }
    );
  }, []);

  useLayoutEffect(() => {

    const tl = new gsap.timeline();
    const trigger = document.getElementById("section--hero");
  }, []);

  const letters = (str, id) => {
    return [...str].map((s) => <span id={id}>{s}</span>);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    columnRefs.current.forEach((columnRef) => {
      if (columnRef) {
        observer.observe(columnRef);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleColumnRef = (ref) => {
    if (ref && !columnRefs.current.includes(ref)) {
      columnRefs.current.push(ref);
    }
  };

  return (
    <>
      <Helmet>
        <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
      </Helmet>
      <Header />
      {/* <Canvas>
        <ScrollControls pages={0}>
          <Letter position={[.9, 2.4, 0]} animation={false} scale={0.08} />
        </ScrollControls>
      </Canvas> */}
      <section id="section--hero" className={Style.mobilebanner}>
        {/* <h1 ref={titleRef} className={`${Style.titleanim} ${classes.title}`} >
          {letters("Digital", "title--1")}
        </h1>
        <h1 ref={subTitleRef} className={`${Style.subtitleanim} ${classes.sub__title}`}>
          <span id="title--2">{letters("World", "title--2")}</span>
        </h1> */}
        {/* <h1 className={Style.sitename} id="heading--hero">DEEP  </h1>
        <h1 className={`${Style.dive} ${Style.sitename}`}  id="heading--hero">DIVE </h1>
        <h1 className={`${Style.media} ${Style.sitename}`}  id="heading--hero"> MEDIA</h1> */}
        <img src="images/mob_logo.png" className={Style.mobile_logo} />
        <h1 className={Style.mob_head}>Are you ready for the real <span>Digital Transformation?!</span></h1>
        <div id="first--div" className={` ${Style.mob_para}`}>
          <h3>
            The agency that covers your digital needs in a creative and
            efficient way
          </h3>
        </div>
        <div >
          <a className={`readmore ${Style.mob_headbtn}`} href="/contact">
            Partner With Us
            <BsArrowUpRight />
          </a>
        </div>
      </section>
      <section id="section--2">
        <div className={Style.mob_cont}>
          <h2>DIVING DEEP into digital depths!</h2>
          <p>
            We, at DeepDive Media, design performance oriented marketing
            strategies to help Advertisers & Brands grow online. We curate the
            customised marketing strategies to help our clients, advertisers and
            brands expand their reach and profits online. With the family of 50+
            teammates, we aim to with the agenda of providing a better ROI.
          </p>
          <a href="/contact" className="readmore">
            Read more
          </a>
        </div>
        {/* <div className={Style.mob_cont}>
          <video
            loop
            style={{ width: "100%" }}
            autoPlay
            muted
            src="/reel1.mp4"
          ></video>
        </div> */}
        <ProjectCounter />
        <GoogleCompaign/>
        <OurCLient />
        {/* <div className={Style.mob_cont}>
          <lottie-player
            src="https://assets8.lottiefiles.com/packages/lf20_rj4titti.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>

          <h2>Our Rocket-Powered Marketing !!!</h2>
          <p>
            Experience marketing that soars to new heights! Just like a rocket
            blazing through the sky, our strategies ignite your success, driving
            ROI higher and higher. Trust us to deliver results that truly take
            off!
          </p>
          <a className="readmore" href="/contact">
            Read more
          </a>
        </div> */}
        <div className={Style.mob_cont}>
          <div>
            <video loop autoPlay muted src="/videos/globe2.mp4"></video>
          </div>
          <div className={Style.mb_mt_2}>
            <h2>DIVING DEEP into digital depths!.</h2>
            <p>
              We, at DeepDive Media, design performance oriented marketing
              strategies to help Advertisers & Brands grow online.
            </p>
            <a className="readmore" href="/contact">
              Read more
            </a>
          </div>
        </div>
      </section>

      <section
        className={`section--service ${classes1.section__service} ${Style.mob_service} `}
      >
        <div className={classes1.inner_row}>
          <div
            className={`${classes1.inner_content} left_side ${Style.mob_service_inner}`}
            id="col1"
          >
            <div>
              <img
                src="/images/standinggirl1.webp"
                alt=""
                className={classes1.girlsimg}
              />
              <h2>Built for the future of B2B marketing.</h2>
              <p>
                B2B marketing is complex. Stryve has built a team of full-stack
                marketers, designers, and developers who understand what
                companies need to grow quickly.
              </p>
            </div>
          </div>
          <div className={classes1.all_services} id="col2">
            <div className="service_col" ref={handleColumnRef}>
              <img src="/images/media.svg" alt="" />
              <h3>Media Buying</h3>
              <p>
                Get hands on results with research, planning, and rising ROI
                with us!.
              </p>
            </div>
            <div className="service_col" ref={handleColumnRef}>
              <img src="/images/email.svg" alt="" />
              <h3>Email Marketing</h3>
              <p>
                Strategic Email marketing isn’t everyone’s forte. For us, it is
                indeed!
              </p>
            </div>
            <div className="service_col" ref={handleColumnRef}>
              <img src="/images/social.svg" alt="" />
              <h3>Social Media Marketing</h3>
              <p>
                Social media marketing is the secret of success, and we aim only
                at such success.
              </p>
            </div>
            <div className="service_col" ref={handleColumnRef}>
              <img src="/images/influencer.svg" alt="" />
              <h3>Influencers Marketing</h3>
              <p>
                Fruitful collabs, best endorsements, and innovative marketing is
                our cup of tea!
              </p>
            </div>
            <div className="service_col" ref={handleColumnRef}>
              <img src="/images/content.svg" alt="" />
              <h3>Content Marketing</h3>
              <p>
                Smart content strategies for best marketing solutions is our
                expertise.
              </p>
            </div>
            <div className="service_col" ref={handleColumnRef}>
              <img src="/images/Lead.svg" alt="" />
              <h3>Video and Branding</h3>
              <p>
                Capturing the best marketing and branding is something we never
                run away from.
              </p>
            </div>
            <div className="service_col" ref={handleColumnRef}>
            <a href="/googleads"><img src="/images/Protection.svg" alt="" />
              <h3>Google Ad protection</h3>
              <p>
              With our strategies, we help you tackle click fraud head-on, safeguarding your advertising investments and driving meaningful results.
              </p></a>
            </div>
          </div>
        </div>
      </section>
      <Testimonial />
      <Footer />
    </>
  );
}

export default MobileHome;
