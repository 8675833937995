import React, { Component } from 'react';
import AboutBanner from './AboutBanner';
import AboutContact from './AboutContact';
import AboutTeam from './AboutTeam';
import Footer from './Footer';
import GoogleCompaign from './GoogleCompaign';
import Header from './Header';
import OurCLient from './OurClient';
import ProjectCounter from './ProjectCounter';
import Testimonial from './Testimonial';
function AboutContent(props) {
    return ( 
        <>
            <Header  />
            
            <AboutBanner />
            <div className='project_completion'>
                <ProjectCounter />
            </div>
            <AboutTeam />
            <GoogleCompaign/>
            {/* <OurCLient/> */}
            {/* <Testimonial/> */}
            <AboutContact />
            <div className="footer_container">
                <Footer/>
            </div>
        </>
     );
}

export default AboutContent;