import React, { useEffect } from "react";
import classes from "../styles/SectionProjects.module.css";
import { BsArrowRight } from "react-icons/bs";

const SectionProjects = (props) => {
  function handleMouseMove(e) {
    const img = e.target.nextElementSibling;

    if (img) {
      img.style.left = `${(e.screenX / window.innerHeight) * 100}px`;
      img.style.top = `${(e.clientY / window.innerWidth) * 300}px`;
    }
  }

  return (
    <section style={props.styles} className={classes.section__projects}>
      <h1 className={classes.main__heading}>Recent Projects</h1>
      <div className={classes.inner__section}>
        <div className={` ${classes.project} ${classes.project__1}`}>
          <h1 onMouseMove={handleMouseMove}>Coupons Sites</h1>
          <img src="/images/coupons.webp" />
        </div>
        <div className={` ${classes.project} ${classes.project__2}`}>
          <h1 onMouseMove={handleMouseMove}>Travel Sites</h1>
          <img src="/images/airline.webp" />
        </div>
        <div className={` ${classes.project} ${classes.project__3}`}>
          <h1 onMouseMove={handleMouseMove}>Reviews Sites</h1>
          <img src="/images/review.webp" />
        </div>
        <div></div>
        <div className={` ${classes.project} ${classes.project__4}`}>
          <h1 onMouseMove={handleMouseMove}>Lead Generation</h1>
          <img src="/images/leadgen.webp" />
        </div>
        <div className={` ${classes.project} ${classes.project__5}`}>
          <h1 onMouseMove={handleMouseMove}>Games Creation</h1>
          <img src="/images/gaming.webp" />
        </div>
        <div className={` ${classes.project} ${classes.project__6}`}>
          <h1 onMouseMove={handleMouseMove}>Jobs Finder</h1>
          <img src="/images/jobs.webp" />
        </div>
      </div>
      <div className={classes.btn__wrapper}>
        <button>
          <a href="/contact">
            More <BsArrowRight />
          </a>
        </button>
      </div>
    </section>
  );
};

export default SectionProjects;
