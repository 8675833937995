import React, { useEffect,useRef } from 'react';
import Style from '../styles/About.module.css'
import { MdOutlineKeyboardDoubleArrowDown } from 'react-icons/md';
function ServiceBanner() {
  const columnRefs = useRef([]);
  useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible');
            } else {
              entry.target.classList.remove('visible');
            }
          });
        },
        { threshold: 0.1 }
      );
  
      columnRefs.current.forEach((columnRef) => {
        if (columnRef) {
          observer.observe(columnRef);
        }
      });
  
      return () => {
        observer.disconnect();
      };
    }, []);
  
    const handleColumnRef = (ref) => {
      if (ref && !columnRefs.current.includes(ref)) {
        columnRefs.current.push(ref);
      }
    };
  return (
    <>
      <section className={Style.aboutbanner}>
        <div className={Style.downArrow}>
          <a href="#allservices" className={Style.arrow_down}> <MdOutlineKeyboardDoubleArrowDown /> </a>
        </div>
        <video
          loop
          style={{ width: "100%" }}
          autoPlay
          muted
          src="/reel1.mp4"
        ></video>
      </section>
      <section className={Style.aboutquote}>
        <h2 className='firstdiv' ref={handleColumnRef}>Our Services</h2>
       <p className='firstdiv' ref={handleColumnRef}>We strive to bring cutting edge innovation and transform the digital marketing space with effective marketing strategies and services. Our efforts are focused to revolutionise the approach towards branding and online marketing horizon, for enhanced user experience along with result oriented solutions for the advertisers.</p>
      </section>

    </>
  );
}

export default ServiceBanner;