import React, { Component } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Style from '../styles/Privacy.module.css'
function PrivacyPolicy() {
    return (
        <>
            <Header />
            <section>
                <div className={Style.cust_conateiner}>
                    <h1>Introduction and Purpose of Privacy Policy</h1>
                    <p>Welcome to the Privacy Policy of DeepDive Media Pvt. Ltd ("DeepDive Media," "DDM," "DeepDive Media Pvt. Ltd.," "us," "we," or "our"). This Privacy Policy aims to outline how we collect, utilize, and share information about you when you interact with our website, DeepDiveMedia.in. We kindly request you to carefully read this to gain a comprehensive understanding of our privacy practices. Should you have any queries regarding our Privacy Policy, please don't hesitate to contact us at <a href='mailto:privacy@deepdivemedia.in'>privacy@deepdivemedia.in</a> </p>
                   <p> we handle the information received via our website at deepdivemedia.in (the "Site"), our support platform, and the tools and services offered on the Site (the "Service"). Most importantly, by <a>  https://ddtrack.io/ </a> , our exclusive tool assisting in preventing fraud clicks.</p>
                    <p>DeepDive Media is a limited liability company, incorporated under the laws of India, and our principal place of business is located in 5th Floor, 500, ITL Twin Tower, Netaji Subhash Place, Pitampura, North West Delhi, Delhi, 110034, India. If you reside or are situated in the European Economic Area ("EEA"), DeepDive Media Pvt. Ltd. acts as the data controller for all Personally Identifiable Information (as described below) collected through our website, as outlined in this Privacy Policy.</p>
                    <p>At DeepDive Media Pvt. Ltd., we prioritize the security and confidentiality of our visitors' personal information. This Privacy Policy aims to provide you with an understanding of how we handle and safeguard any personal data you provide to us or that we collect during your visit to our website.</p>
                    <p>Please note that we may update this Policy periodically, and we encourage you to review it regularly to stay informed about any modifications made to our Privacy Policy.</p>
                    <h2>What Information This Privacy Policy Covers</h2>
                    <p>This Privacy Policy pertains to the information we collect from you through our website. While certain functionalities of our website can be accessed without revealing any Personally Identifiable Information, accessing specific features and benefits may require you to submit or for us to collect "Personally Identifiable Information" (PII), which refers to information that can be used to identify you. Such PII may include your name, email address, and other relevant details. If you are situated in the EEA, it may also encompass your IP address or device identifier. It is your responsibility to ensure the accuracy of the Personally Identifiable Information you provide to us. Inaccurate information could impact your ability to use the website effectively, receive relevant information, and hinder our communication with you. Thus, it is crucial to keep your email address up to date as it serves as one of our primary communication channels with you.</p>
                    <h2>What You Agree To By Using Our Site</h2>
                    <p>By voluntarily submitting any Personally Identifiable Information to us, you consent and agree that we may collect, use, and disclose such information in accordance with this Privacy Policy and as permitted or required by applicable law. If you do not agree with these terms, please refrain from providing any Personally Identifiable Information. It is important to note that if you refuse or withdraw your consent or choose not to provide the necessary Personally Identifiable Information, we may be unable to provide you with the services and benefits offered on our website. You have the right to withdraw your consent at any time by sending a request to remove consent or by deleting your account at <a href='mailto:privacy@deepdivemedia.in'>privacy@deepdivemedia.in</a> </p>
                    <p>Please be aware that we may also rely on legitimate interests or the fulfillment of a contract to continue processing your Personally Identifiable Information.</p>
                    <h2>What Information We Collect</h2>
                    <p>As part of operating and maintaining our website, we may collect and process the following data related to you:</p>
                    <p>i. Information about your usage of our website, including details of your visits such as pages viewed and the resources you access. This information encompasses traffic data, location data, and other communication data.</p>
                    <p>ii. Information that you provide voluntarily. For instance, when you register for information or make a purchase.</p>
                    <p>iii. Information you provide when communicating with us through any means.</p>
                    <h2>Use of Cookies</h2>
                    <p>Cookies play a crucial role in providing insights about the computer used by a visitor. We may utilize cookies, when appropriate, to gather information about your computer in order to enhance the functionality and improve the user experience of our website.</p>
                    <p>By using cookies, we can collect information about your general internet usage. These cookies are downloaded to your computer's hard drive, without personally identifying you. They provide statistical data that does not reveal any personal details.</p>
                    <p>If you prefer, you can adjust your computer's settings to decline cookies. This can typically be done by activating the "reject cookies" setting on your computer.</p>
                    <p>Please note that we have no control over the cookies used by our advertisers. If applicable, these cookies will be downloaded when you click on advertisements displayed on our website.</p>
                    <h2>How we Use Your Information</h2>
                    <p>We utilize the information related to your website usage to enhance the quality and usefulness of our services. This involves conducting statistical analyses of collective user characteristics and behavior, as well as measuring demographics and interests regarding specific areas of our website. Furthermore, we may employ this information to ensure the security of our services and the website itself. Unless otherwise stated in this Privacy Policy or with your explicit consent, we will not disclose any of your Personally Identifiable Information. In addition to the purposes outlined in this Privacy Policy, we may disclose and utilize Personally Identifiable Information as described below. The information we collect from you is used to provide our services, and it may also be used for one or more of the following purposes:</p>
                    <p>i. Furnishing you with requested information concerning our products or services.</p>
                    <p>ii. Offering information about other products that may be of interest to you. We will only provide such additional information if you have consented to receive it.</p>
                    <p>iii. Notifying you of any changes to our website, services, or products.</p>
                    <p>If you have previously made purchases from us, we may provide details about similar or other goods and services that might pique your interest.</p>
                    <p>In cases where you have granted prior consent, we may allow selected third parties to use your Personally Identifiable Information to provide you with information regarding unrelated goods and services that we believe may be of interest to you. You retain the right to withdraw such consent at any time.</p>
                    <h2>Storing Your Personal Data</h2>
                    <p>If you reside or are located in the EEA, we retain your Personally Identifiable Information only for as long as necessary to fulfill the purposes for which it was collected and processed. The duration for which we retain Personally Identifiable Information depends on the specific purposes of collection and usage, as well as compliance with applicable laws and the establishment, exercise, or defense of our legal rights.</p>
                    <h2>International Transfer of Personally Identifiable Information</h2>
                    <p>We may transfer your personal data from within the EEA to locations outside the EEA. As DeepDive Media is based in India, the processing of your Personally Identifiable Information takes place there. To ensure the protection of international data transfers to DeepDive Media, we have implemented EU Commission certified standard data protection contract clause.</p>
                    <h2>Confidentiality & Security of Personally Identifiable Information</h2>
                    <p>We place great importance on the confidentiality and security of your information. To maintain the confidentiality and security of your Personally Identifiable Information, we employ industry-standard physical, technical, and administrative security measures. Your Personally Identifiable Information will be kept confidential and secure, and we will not share it with third parties unless otherwise specified in this Privacy Policy or in situations where such disclosure is necessary, such as when there is a physical threat to you or others, as permitted by applicable law. However, please note that while we take reasonable steps to protect your information, the transmission of data over the internet is not completely secure. Therefore, we cannot guarantee the security of any data that you choose to send us electronically. The transmission of such information is done at your own risk.</p>
                    <h2>Disclosing Your Information</h2>
                    <p>We are committed to protecting the privacy of your personal information and will not disclose it to any other party except as outlined in this Privacy Policy and in the following circumstances:</p>
                    <p><strong>i. Contractors:</strong>We may engage various service providers, vendors, and contractors ("Contractors") to assist us in delivering our products and services to you. These Contractors may have limited access to your Personally Identifiable Information as necessary to provide their products or services to us, enabling us to offer our services to you. Contractors may include technology vendors, service providers, and content providers involved in the operation and maintenance of the Site or our services. However, Contractors are only granted access to your Personally Identifiable Information to the extent reasonably required for them to perform their specific functions on our behalf.</p>
                    <p><strong>ii. Business Transfer:</strong> In the event of a sale, transfer, or reorganization of all or a portion of our business or assets, including dissolution or liquidation, we reserve the right to transfer Personally Identifiable Information. We will make reasonable efforts to ensure that the transferee handles your Personally Identifiable Information in a manner consistent with this Privacy Policy.</p>
                    <p><strong>iii. Legal Obligations:</strong> We may share your Personally Identifiable Information with government authorities in response to subpoenas, court orders, or other legal processes. This includes situations where we need to establish or exercise our legal rights, protect our property, defend against legal claims, or as otherwise required by law. In such cases, we reserve the right to assert or waive any legal objection or right available to us.</p>
                    <p><strong>iv. Protection of Rights and Safety:</strong> We may disclose your Personally Identifiable Information when we believe it is necessary to investigate, prevent, or take action against illegal or suspected illegal activities. This includes protecting and defending the rights, property, or safety of DeepDive Media Pvt. Ltd., the Site, our users, customers, or others.</p>
                    <h2>Third Party Links</h2>
                    <p>Occasionally, we may include links to third-party websites on our website. Please note that the inclusion of a link does not imply endorsement or approval of the linked site's privacy policy. We recommend reviewing the privacy policy of any third-party website before providing them with any personal data.</p>
                    <h2>Updating or Deleting Your Personally Identifiable Information</h2>
                    <p>If you have any inquiries about your rights or wish to exercise them, you can contact us at <a href='mailto:privacy@deepdivemedia.in'>privacy@deepdivemedia.in</a></p>
                    <p>If you reside or are located in the EEA, you have the following rights:</p>
                    <ul>
                        <li><strong>Access: </strong>You have the right to request access to the Personally Identifiable Information we hold about you.</li>
                        <li><strong>Opt-out: </strong> You can request that we stop processing your Personally Identifiable Information for direct marketing purposes.</li>
                        <li><strong>Correction: </strong> If your Personally Identifiable Information is inaccurate or outdated, you have the right to request its correction.</li>
                        <li><strong>Deletion: </strong> You can request the deletion of your Personally Identifiable Information that we hold.</li>
                        <li><strong>Restriction:</strong> You have the right to request that we limit the processing of your Personally Identifiable Information.</li>
                        <li><strong>Data Portability:</strong> You can request that we provide your Personally Identifiable Information to a third-party service provider.</li>
                        <li><strong>Copy:</strong> You have the right to obtain a copy of the Personally Identifiable Information we hold about you.</li>
                    </ul>
                    <p>We strive to respond to every email promptly, providing our response within the timeframe required by applicable law. However, please note that residual information may remain in our databases, access logs, and other records, which may or may not include your Personally Identifiable Information. </p>
                    <p>Additionally, certain circumstances may exempt certain Personally Identifiable Information from deletion if we are required to continue processing it to fulfill a legal obligation. When submitting a request via email, we may ask you to provide information necessary to confirm your identity.</p>
                    <h2>Questions and Complaints</h2>
                    <p>If you have any questions or concerns regarding this Privacy Policy, please don't hesitate to contact us at <a href='mailto:privacy@deepdivemedia.in'>privacy@deepdivemedia.in</a></p>
                    <h2>Changes to Our Privacy Policy</h2>
                    <p>Please be aware that we regularly review our privacy practices, and they are subject to change. Any changes, updates, or modifications will be effective immediately upon posting on our website. We will notify you of any material changes to this Privacy Policy by posting a notice on our website's homepage for a reasonable period of time following the update and by updating the effective date at the bottom of this page. We encourage you to periodically revisit this page to stay informed about the most current version of our Privacy Policy.</p>
                </div>
            </section>
            <div className="footer_container"> <Footer /></div>
        </>
    );
}

export default PrivacyPolicy;