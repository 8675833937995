import React, {useEffect, Component } from 'react';
import { RxArrowTopRight } from 'react-icons/rx';
import Style from '../styles/Contact.module.css'
import gsap from 'gsap';

function Thanyou() {

    useEffect(() => {
        const thankudiv = document.querySelectorAll(".thankutxt")
        gsap.fromTo(thankudiv, { transform: 'translateY(100%)', opacity: 0 }, { transform: 'translateY(0)', ease: 'power1.easeOut', opacity: 1 })
    }, [])
    return (
        <>
            <div className={Style.thankyouCont}>
                <h2 className='thankutxt'>Thank you for contacting us. <br /> We will come back to you as soon as possible.</h2>
                <a className={`text-decoration-none thankutxt ${Style.sendback}`} href="/" >
                    <RxArrowTopRight/>
                    Go back to Home</a>
            </div>
        </>
    );
}

export default Thanyou;