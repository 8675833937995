import React, { useRef, useState } from "react";
import classes from "../styles/header.module.css";
import { ImCross } from "react-icons/im";
import gsap from "gsap";
import {AiFillFacebook,AiFillInstagram, AiFillLinkedin} from "react-icons/ai";
const Header = (props) => {
  const sliderRef = useRef();
  const burgerRef = useRef();
  const [background, setBackground] = useState("");
  const [textColor, setTextColor] = useState("");

  function showNavbar() {
    sliderRef.current.classList.add(`${classes.show__full}`);
    
    // setTimeout(() => {
    //   props.setPages(0);
    // }, 900);
  }
  function removeNavbar() {
    sliderRef.current.classList.remove(`${classes.show__full}`);
   
    
    // setTimeout(() => {
    //   props.setPages(props.initialPage);
    // }, 900);
  }

  function handleMouseMove(e, key) {
    setBackground(key);

    e.target.classList.add(`${key}--color--link`);

    const img = e.target.children[0];

    if (img) {
      img.style.left = `${e.clientX}px`;
    }
  }

  function handleMouseLeave(e, key) {
    setBackground("none");
    e.target.classList.remove(`${key}--color--link`);

    e.target.classList.remove(`${classes.above}`);
  }

  return (
    <>
      <header className={classes.header}>
        <div className={classes.logo__box}>
          <a href="/">
            <img src="/images/logo.svg" />
          </a>
        </div>
        <div
          ref={burgerRef}
          onClick={() => showNavbar()}
          className={classes.burger}
        >
          <span className={classes.line__1}></span>
          <span className={classes.line__2}></span>
          <span className={classes.line__3}></span>
        </div>
      </header>
      <div
        id="mobile_menu"
        ref={sliderRef}
        className={`${classes.slider__navbar} ${background === "none" ? classes.show__full : ""
          } ${background === "home" ? classes.slider__home : ""}
        ${background === "contact" ? classes.slider__cntct : ""}
        ${background === "about" ? classes.slider__about : ""}
        ${background === "service" ? classes.slider__service : ""}
        ${background === "workwithus" ? classes.slider__work : ""}
        `}
      >
        <div className={classes.cross}>
          <div className={classes.logo__box__blck}>
            <img src="/images/logo.svg" />
          </div>

          <button onClick={() => removeNavbar()}>
            <ImCross />
            CLOSE
          </button>
        </div>
        <div className={classes.inner__nav}>
          <a
            onMouseOut={(e) => handleMouseLeave(e, "home")}
            onMouseMove={(e) => handleMouseMove(e, "home")}
            href="/"
          >
            Home
            <div className={classes.img_wrapper}>
              <img src="/images/nav1.webp" />
            </div>
          </a>
          <a
            onMouseOut={(e) => handleMouseLeave(e, "about")}
            onMouseMove={(e) => handleMouseMove(e, "about")}
            href="/about"
          >
            About
            <div className={classes.img_wrapper}>
              <img src="/images/nav3.png" />
            </div>
          </a>
          
          <a
            onMouseOut={(e) => handleMouseLeave(e, "service")}
            onMouseMove={(e) => handleMouseMove(e, "service")}
            href="/service"
          >
            Service
            <div className={classes.img_wrapper}>
              <img src="/images/nav4.png" />
            </div>
          </a>
          <a
            onMouseOut={(e) => handleMouseLeave(e, "contact")}
            onMouseMove={(e) => handleMouseMove(e, "contact")}
            href="/contact"
          >
            Contact
            <div className={classes.img_wrapper}>
              <img src="/images/contact.png" />
            </div>
          </a>
          {/* <a
            onMouseOut={(e) => handleMouseLeave(e, "workwithus")}
            onMouseMove={(e) => handleMouseMove(e, "workwithus")}
            href="/workwithus"
          >
            Work with Us
            <div className={classes.img_wrapper}>
              <img src="/images/workwithus.png" />
            </div>
          </a> */}
        </div>
        <div className={classes.bottom_header}>
          <h3>2023 - © Deepdive Media</h3>
          <div className={classes.social__icons}>
            <a href="https://www.facebook.com/deepdivemedia.in/"><AiFillFacebook /></a>
            <a href="https://www.instagram.com/_deepdivemedia/"><AiFillInstagram /></a>
            <a href="https://in.linkedin.com/company/deepdive-media"><AiFillLinkedin /></a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
