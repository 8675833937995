import React, { Component } from 'react';
import Style from '../styles/Work.module.css'
function Ctasec() {
    return (
        <>
            <section className={Style.ctasec}>
                <div className={Style.cust_container}>
                    <div className={Style.ctawidth}>
                        <img src="/images/" alt="" />
                        <h2>Sounds Good? </h2>
                        <p>Want to know about our hiring process? Have questions? Connect with us right here!.</p>
                        <a className="readmore" href="/contact">Contact Us</a>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Ctasec;