import React, { useEffect, useRef, useState } from 'react';
import Style from '../styles/Work.module.css'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
function AboutWork() {
    const columnRefs = useRef([]);
    const [isAtTop, setIsAtTop] = useState(false);
    const [attop, setattop] = useState(false);
    const [isComplete, setisComplete] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
                entries.forEach((entry) => {
              if (entry.isIntersecting ) {
                entry.target.classList.add('setposition');
              } else {
                entry.target.classList.remove('setposition');
              }
            });
          },
          { threshold: 0.1 }
        );
    
        columnRefs.current.forEach((columnRef) => {
          if (columnRef) {
            observer.observe(columnRef);
          }
        });
    
        return () => {
          observer.disconnect();
        };
      }, []);
    
      const handleColumnRef = (ref) => {
        if (ref && !columnRefs.current.includes(ref)) {
          columnRefs.current.push(ref);
        }
      };

      useEffect(() => {
        const handleScroll = (event) => {

          const step1 = document.getElementById('step1');
          const { top } = step1.getBoundingClientRect();
    
          if (top <= 0) {
            setIsAtTop(true);
          } else {
            setIsAtTop(false);
          }
         
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
      useEffect(() => {
        const handleScroll = () => {
          const step2 = document.getElementById('step2');
          const { top } = step2.getBoundingClientRect();
          if (top <= 100) {
            setattop(true);
          } else {
            setattop(false);
          }
         
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  
      useEffect(() => {
        const handleScroll = () => {
          const step3 = document.getElementById('step3');
          const { top } = step3.getBoundingClientRect();
          if (top <= 190) {
            setisComplete(true);
          } else {
            setisComplete(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
    return (
        <>
            <section className={`${Style.whatwedo}  `} ref={handleColumnRef}>
                <div className={`${Style.aboutwork} step1 ${isAtTop ? 'at-top' : ''} `} id="step1">
                    <div className={Style.cust_container}>
                        <a href="#" className={`${Style.work_detail}`}><span>1</span> Dynamic Work Culture</a>
                        <div className={Style.cust_row}>
                            <img src="/images/Dynamic-Work-Culture.jpg" alt="" className={ Style.workImg}/>
                            <div className={Style.work_content}>
                                <h2>Ignite Your Potential in a Culture of Excellence!</h2>
                                <p>We foster an epic work culture that 'fuels growth'. Step into our dynamic work place, where regular events and celebrations uplift spirits, where freedom in working grows creativity, and where absence of micro-management empowers us to achieve!</p>
                                <a href="/contact" className='readmore'>Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${Style.aboutwork} step2 ${attop ? 'reach_top' : ''} `} id="step2" >
                    <div className={Style.cust_container}>
                        <a href="#" className={`${Style.work_detail}`}><span>2</span> Premium Facilities</a>
                        <div className={Style.cust_row}>
                            <img src="/images/Premium-Facilities.jpg" alt="" className={ Style.workImg}/>
                            <div className={Style.work_content}>
                                <h2>The Only Space Where Work Feels Like a Retreat!</h2>
                                <p>We prioritise Comfort and Convenience via offering lush green space, a pantry with delightful snacks, cozy bean bags, optimal lighting, endless coffee, MacBooks & other tech-essentials, and much much more!</p>
                                <a href="/contact" className='readmore'>Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${Style.aboutwork} step3 ${isComplete ? "scroll_completd" : ""}`} id="step3">
                    <div className={Style.cust_container}>
                        <div className={Style.headWidth}><a href="#" className={`${Style.work_detail}`}><span>3</span> Growth and Financial Stability</a></div>
                        <div className={Style.cust_row}>
                            <img src="/images/growth.jpg" alt="" className={ Style.workImg}/>
                            <div className={Style.work_content}>
                                <h2>Embracing Talent, Elevating Careers</h2>
                                <p>At our company, we provide extensive growth opportunities and ensure financial stability for our teammates. We prioritise hiring talent that has a 'little spark' and honing their skills across various domains, to let them spread 'fire' in the future!</p>
                                <a href="/contact" className='readmore'>Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutWork;