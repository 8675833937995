import React from "react";
import classes from "../styles/ProfileCard.module.css";
import {
  BsFacebook,
  BsLinkedin,
  BsWhatsapp,
  BsTelegram,
  BsSkype,
  BsInstagram,
} from "react-icons/bs";
import { AiFillInstagram, AiFillLinkedin } from "react-icons/ai";
import { SiGmail } from "react-icons/si";

const ProfileCard = (props) => {
  if (props.name === "shashank") {
    return (
      <div className={classes.card}>
        <div className={classes.card__top}>
          <img src="/images/card-bg.svg" className={classes.bg__card} />

          <div className={classes.user__img}>
            <img src="/images/Shashank.png" />
          </div>
        </div>
        <div className={classes.card__cnt}>
          <h3>Shashank Chhabra</h3>
          <h4>Founder & Managing director</h4>
          <div className={` ${classes.links__wrap}`}>
            <a href="https://www.linkedin.com/in/shashankchhabra/">
              <BsLinkedin />
            </a>
            <a href="skype:shashank4538?chat">
              <BsSkype />
            </a>

            <a href="https://telegram.me/The_Shaw_Shank">
              <BsTelegram />
            </a>

            <a href="https://www.facebook.com/shashank.chhabra.6">
              <BsFacebook />
            </a>

            <a href="https://wa.me/+919034616433">
              <BsWhatsapp />
            </a>

            <a href="https://www.instagram.com/the_shaw_shank/">
              <BsInstagram />
            </a>
            <a href="mailTo:shashank@deepdivemedia.in">
              <SiGmail />
            </a>
          </div>
        </div>
        <div className={classes.card__footer}>
          <a href="/">
            <p>www.deepdivemedia.in</p>
          </a>
          <span></span>
        </div>
      </div>
    );
  }
  if (props.name === "shubham") {
    return (
      <div className={classes.card}>
        <div className={classes.card__top}>
          <img src="/images/card-bg.svg" className={classes.bg__card} />

          <div className={classes.user__img}>
            <img src="/images/Shubham.png" />
          </div>
        </div>
        <div className={classes.card__cnt}>
          <h3>Shubham Dhamija</h3>
          <h4>strategy head</h4>
          <div className={` ${classes.links__wrap}`}>
            <a href="https://www.linkedin.com/in/shubham-dhamija-62950645/">
              <BsLinkedin />
            </a>
            <a href="skype:live:.cid.e53090522ec2bf11?chat">
              <BsSkype />
            </a>

            <a href="https://telegram.me/dshubham26">
              <BsTelegram />
            </a>

            <a href="https://www.facebook.com/dshubham25">
              <BsFacebook />
            </a>

            <a href="https://wa.me/+919034901070">
              <BsWhatsapp />
            </a>

            <a href="https://www.instagram.com/dshubham26/">
              <BsInstagram />
            </a>
            <a href="mailTo:shubham.dhamija@deepdivemedia.in">
              <SiGmail />
            </a>
          </div>
        </div>
        <div className={classes.card__footer}>
          <a href="/">
            <p>www.deepdivemedia.in</p>
          </a>
          <span></span>
        </div>
      </div>
    );
  }
  if (props.name === "tanveer") {
    return (
      <div className={classes.card}>
        <div className={classes.card__top}>
          <img src="/images/card-bg.svg" className={classes.bg__card} />

          <div className={classes.user__img}>
            <img src="/images/Tanveer.png" />
          </div>
        </div>
        <div className={classes.card__cnt}>
          <h3>Tanveer Singh</h3>
          <h4>Delivery head</h4>
          <div className={` ${classes.links__wrap}`}>
            <a href="https://www.linkedin.com/in/tanveer-singh-92450419a/">
              <BsLinkedin />
            </a>
            <a href="skype:live:.cid.5aa0fde818f14639?chat">
              <BsSkype />
            </a>

            <a href="https://telegram.me/Tanveer_sb">
              <BsTelegram />
            </a>

            <a href="https://www.facebook.com/tanveersingh.bains.3">
              <BsFacebook />
            </a>

            <a href="https://wa.me/+919780969739">
              <BsWhatsapp />
            </a>

            <a href="https://www.instagram.com/tanveer_singh_sb/">
              <BsInstagram />
            </a>
            <a href="mailTo:tanveer.singh@deepdivemedia.in">
              <SiGmail />
            </a>
          </div>
        </div>
        <div className={classes.card__footer}>
          <a href="/">
            <p>www.deepdivemedia.in</p>
          </a>
          <span></span>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.card}>
      <div className={classes.card__top}>
        <img src="/images/card-bg.svg" className={classes.bg__card} />

        <div className={classes.user__img}>
          <img src="/images/Arshdeep.png" />
        </div>
      </div>
      <div className={classes.card__cnt}>
        <h3>Arshdeep Singh</h3>
        <h4>Founder & Chief Executive Officer</h4>
        <div className={` ${classes.links__wrap}`}>
          <a href="https://www.linkedin.com/in/arshdeepsinghbains/">
            <BsLinkedin />
          </a>
          <a href="skype:live:.cid.c744de1118029751?chat">
            <BsSkype />
          </a>

          <a href="https://telegram.me/Deepdivemedia">
            <BsTelegram />
          </a>

          <a href="https://www.facebook.com/arshdeepsb">
            <BsFacebook />
          </a>

          <a href="https://wa.me/+919988131320">
            <BsWhatsapp />
          </a>

          <a href="https://www.instagram.com/arshdeepsb/">
            <BsInstagram />
          </a>
          <a href="mailTo:director@deepdivemedia.in">
            <SiGmail />
          </a>
        </div>
      </div>
      <div className={classes.card__footer}>
        <a href="/">
          <p>www.deepdivemedia.in</p>
        </a>
        <span></span>
      </div>
    </div>
  );
};

export default ProfileCard;
