import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Service from "./pages/Service";
import Workwithus from "./pages/workwithus";
import UserCard from "./pages/UserCard";
import GoogleAds from "./pages/GoogleAds";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  useEffect(() => {
    global.requestAnimationFrame = function (callback) {
      setTimeout(callback, 0);
    };
  }, []);
  useEffect(() => {
    if (window.location.pathname === "/") {
      document.body.classList.add("home-page");
    } else {
      document.body.classList.remove("home-page");
    }
    if (
      window.location.pathname == "/about" ||
      window.location.pathname == "/service"
    ) {
      document.body.classList.add("about-page");
    } else {
      document.body.classList.remove("about-page");
    }
    if (window.location.pathname == "/workwithus") {
      document.body.classList.add("work-page");
    } else {
      document.body.classList.remove("work-page");
    }
  }, []);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/service" element={<Service />} />
          <Route path="/workwithus" element={<Workwithus />} />
          <Route path="/googleads" element={<GoogleAds />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/arsh" element={<UserCard />} />
          <Route path="/shashank" element={<UserCard name="shashank" />} />
          <Route path="/shubham" element={<UserCard name="shubham" />} />
          <Route path="/tanveer" element={<UserCard name="tanveer" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
