import React, { Component } from 'react';
import AboutWork from '../components/AboutWork';
import Ctasec from '../components/CtaSec';
import Footer from '../components/Footer';
import Header from '../components/Header';
import OpeningJob from '../components/OpeningJob';
import WorkBanner from '../components/WorkBanner';

function Workwithus() {
    return ( 
        <>
            <Header />
            <WorkBanner />
            <AboutWork />
            <OpeningJob/>
            <Ctasec/>
            <div className="footer_container">
                <Footer/>
            </div>
        </>
     );
}

export default Workwithus;