import React, {  useState,  useEffect } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { RiArrowGoForwardFill } from 'react-icons/ri';
import Style from '../styles/Contact.module.css'
import gsap from 'gsap';
import Thanyou from './Thankyou';

function ContactDetail() {
    const [openForm, setOpenForm] = useState(false)
    const [step, setstep] = useState(1)
    const [firstName, setFirstname] = useState("");
    const [LastName, setLastname] = useState("");
    const [projectDet, setprojectDet] = useState("");
    const [email, setEmail] = useState("");
    const [jobposition, setJobPosition] = useState("");
    const [animate, setAnimate] = useState()
    const [errMssg, seterrMssg] = useState(false)

    function handleForm(val) {
        setOpenForm(val)
        setstep(1)
        setAnimate(val)
    }

    useEffect(() => {
        const first_div = document.querySelectorAll(".firstdiv")
        const stepcounter = document.querySelectorAll("stepcounter")
        gsap.fromTo(first_div, { transform: 'translateY(100%)', opacity: 0 }, { transform: 'translateY(0)', ease: 'power1.easeOut', opacity: 1 })
        gsap.fromTo(stepcounter, { transform: 'translateY(100%)', opacity: 0 }, { transform: 'translateY(0)', ease: 'power1.easeOut', opacity: 1 })
    }, [])


    useEffect(() => {
        const el = document.querySelectorAll('.label--text')
        const inputfield = document.querySelectorAll('.field_box')
        gsap.fromTo(el, { transform: 'translateY(100%)' }, { transform: 'translateY(0)' })
        gsap.fromTo(inputfield, { transform: 'scaleX(0%)' }, { transform: 'scaleX(100%)',ease: 'Power0.easeOut' })
    }, [animate])


    
    function handleNext() {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = regex.test(email);
        if (step == 1) {
            if (firstName !== "" && LastName !== "" && email !== "") {
                if (isValid) {
                    seterrMssg(false)
                    setstep(step + 1)
                    setAnimate(step)

                } else {
                    seterrMssg("Email is not Valid")
                }
            } else {
                seterrMssg("All the fields are required")
            }
        } else if(step == 2){
            if (projectDet !== "") {
                setstep(step + 1)
                seterrMssg(false)
                setAnimate(step)

            } else {
                seterrMssg("Add the Project Detail")
            }

        }
    }
    function handleBack() {
        seterrMssg(false)
        if (step == 1) {
            setOpenForm(false)
            setFirstname("")
            setLastname("")
            setEmail("")
        } else {
            if (step == 2) {
                setprojectDet("")
            }
            setstep(step - 1)
        }
      
    }

    function handleFirstName(e) {
        setFirstname(e.target.value)
    }

    return (
        <>
            <section id='section' className={Style.contact_page}>
                <div className={Style.cust_container}>
                    {openForm ? "" : <div className={Style.contactCol}> <div>
                        <span className={`firstdiv ${Style.subtitle}`}>Contact</span>
                        <h2 className='firstdiv'>What are you searching for ?</h2>
                        <ul className={Style.link}>
                            <li onClick={() => handleForm("start-a-project")}>Business Query</li>
                            <li onClick={() => handleForm("say-hello")}>Contact Us</li>
                            <li onClick={() => handleForm("apply")}>Apply For Job</li>
                        </ul>
                    </div>
                        <div>
                            <img src="/images/contactrt.png" alt="" />
                    </div>
                    </div>
                    }

                    {/* <StartProject fncall={ handlenext}/> */}
                    {openForm ? <div>

                        {step == 3  ? "" : <div onClick={handleBack} className={Style.border_arrow}><BsArrowLeft /> Back</div>}

                        {
                            step == 1 ? <div className={`first_step ${Style.formContainer}`}>
                                <div className={Style.formgroup}>
                                    <label htmlFor="" className={`label--text  ${Style.lableName} ${firstName != "" ? "hidelabel" : ""}`}>{openForm == "start-a-project" ? "Company Name" : "First Name"}</label>
                                    <input type="text" className='field_box' value={firstName} onChange={handleFirstName} placeholder="firstname" required />
                                </div>
                                <div className={Style.formgroup}>
                                    <label htmlFor="" className={`label--text ${Style.lableName} ${LastName != "" ? "hidelabel" : ""}`}>{openForm != "say-hello" ? "Phone Number" : "Last Name" }</label>
                                    <input type={openForm != "say-hello" ? "number" : "text"} className='field_box' value={LastName} onChange={(e) => setLastname(e.target.value)} required placeholder='lastname' />
                                </div>
                                <div className={Style.formgroup}>
                                    <label htmlFor="" className={`label--text ${Style.lableName} ${email != "" ? "hidelabel" : ""} ${errMssg == "Email is not Valid" ? Style.hide : ""}`}>Email </label>
                                    <input type="email" className='field_box' value={email} onChange={(e) => setEmail(e.target.value)} required placeholder='email' />
                                </div>
                                {openForm == "apply" ?
                                    <div className={Style.formgroup}>
                                        <label htmlFor="" className={`label--text ${Style.lableName} ${jobposition != "" ? "hidelabel" : ""} `}>Applying For? </label>
                                        <input type="email" className='field_box' value={jobposition} onChange={(e) => setJobPosition(e.target.value)} required placeholder='email' />
                                     </div> : ""}
                            </div> : step == 2 ?

                                openForm == "start-a-project" ?
                                    <div className={`second_step`}>
                                        <div className={Style.formgroup}>
                                            <label htmlFor="" className={`label--text ${Style.lableName}  ${projectDet != "" ? "hidelabel" : ""}  `}>Agenda / Purpose</label>
                                            <textarea id="" className={`field_box ${Style.projectDes}`} rows={5} onChange={(e) => setprojectDet(e.target.value)} required></textarea>
                                        </div>
                                    </div> :
                                    openForm == "say-hello" ?
                                        <div className={`second_step`}>
                                            <div className={Style.formgroup}>
                                                <label htmlFor="" className={`label--text ${Style.lableName}  ${projectDet != "" ? "hidelabel" : ""} `}>Your Message</label>
                                                <textarea id="" className={`field_box ${Style.projectDes}`} rows={5} onChange={(e) => setprojectDet(e.target.value)} required></textarea>
                                            </div>
                                        </div> :
                                        <div className={`second_step`}>
                                            <div className={Style.formgroup}>
                                                <label htmlFor="" className={`label--text ${Style.lableName}  ${projectDet != "" ? "hidelabel" : ""} `}>Brief Skills / Experience</label>
                                                <textarea id="" className={`field_box ${Style.projectDes}`} rows={5} onChange={(e) => setprojectDet(e.target.value)} required></textarea>
                                            </div>
                                        </div>

                                : <Thanyou />

                        }
                        {errMssg ? <p className={Style.errMsg}>{errMssg}</p> : ""}

                        {step == 3 ? "" : <div className={Style.bottomArea}>
                            <div>
                                <h5 className={Style.progressStep}> <span id='stepcounter'>0{step}</span>  - 02 </h5>
                            </div>
                            <div className={Style.nextBtn} onClick={handleNext}>
                                <RiArrowGoForwardFill />
                                Next
                            </div>
                        </div>}

                    </div> : ""}



                </div>
            </section>

        </>
    );
}

export default ContactDetail;