import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import { ScrollControls, Scroll, Sparkles } from "@react-three/drei";
import { OrbitControls } from "@react-three/drei";
import gsap from "gsap";
import Letter from "../components/Letter";
import HomeContent from "../components/HomeContent";
import Services from "../components/Services";
import { Canvas } from "@react-three/fiber";
import MobileHome from "../components/MobileHome";
import WebGL from "three/addons/capabilities/WebGL.js";
import DialogBox from "../components/DialogBox";
const Home = () => {
  const [cursor, setCursor] = useState(null);
  const scrollerRef = useRef(null);

  const [pages, setPages] = useState(9.4);
  const [pagessize, setPageSize] = useState();
  const [isWebGLdisable, setWebGLdisable] = useState(false);
  useEffect(() => {
    try {
      if (!WebGL.isWebGLAvailable()) {
        setWebGLdisable(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);
  useEffect(() => {
    function setpage() {
      if (window.innerWidth > 2150) {
        setPages(9);
      }
      if (window.innerWidth > 2000 && window.innerWidth <= 2150) {
        setPages(9.7);
      }
      if (window.innerWidth > 1799 && window.innerWidth <= 2000) {
        setPages(9.3);
      }
      if (window.innerWidth > 1600 && window.innerWidth <= 1799) {
        setPages(9.8);
      }
      if (window.innerWidth > 1441 && window.innerWidth <= 1600) {
        setPages(9.83);
      }
      if (window.innerWidth > 1400 && window.innerWidth <= 1441) {
        setPages(10.5);
      }

      if (window.innerWidth > 1200 && window.innerWidth <= 1400) {
        setPages(10.4);
      }
      if (window.innerWidth > 1100 && window.innerWidth <= 1200) {
        setPages(10.57);
      }
      if (window.innerWidth > 1023 && window.innerWidth <= 1100) {
        setPages(7.9);
      }
      if (window.innerWidth > 991 && window.innerWidth <= 1023) {
        setPages(10.55);
      }

      if (window.innerWidth <= 991) {
        setPages(12.2);
      }
      
      if (window.innerWidth <= 820 && window.innerWidth <= 822) {
        setPages(9.4);
      }
      if (window.innerWidth <= 767) {
        setPages(8.5);
      }
      if (window.innerWidth <= 576) {
        setPages(9);
      }
    }
    setPageSize(window.innerWidth);
    setpage();
    // window.addEventListener("resize", (e) => {
    //   if (window.innerWidth < 1799) {
    //     setPages(7.8);
    //   }
    //   if (window.innerWidth <= 1100) {
    //     setPages(8.4);
    //   }

    //   if (window.innerWidth <= 991) {
    //     setPages(8.2);
    //   }
    //   if (window.innerWidth <= 767) {
    //     setPages(8.5);
    //   }
    //   if (window.innerWidth <= 576) {
    //     setPages(9);
    //   }

    // });
  }, [window.innerWidth]);
console.log(pagessize, pages);
  useEffect(() => {
    function preventBehavior(event) {
      event.preventDefault();
    }

    document.addEventListener("touchstart", function () {}, { passive: false });

    const tl = new gsap.timeline();
    tl.fromTo(
      "#title--1",
      {
        transform: "translateY(100%)",
      },
      {
        transform: "translateY(0)",
        stagger: 0.1,
        duration: 0.6,
      }
    );
    tl.fromTo(
      "#title--2",
      {
        transform: "translateY(100%)",
      },
      {
        transform: "translateY(0)",
        stagger: 0.1,
        duration: 0.6,
      },
      "-=1"
    );
    tl.fromTo(
      "#first--div",
      {
        x: "-200%",
      },
      { x: "0%", duration: 0.9 }
    );
  }, []);
  if (pagessize <= 768) {
    return (
      <>
        <MobileHome />
      </>
    );
  } else if (pagessize == undefined) {
    return <></>;
  } else {
    return (
      <>
        {!isWebGLdisable ? (
          <Canvas shadows>
            <color attach="background" args={["#000000"]} />

            <ScrollControls pages={pages} damping={0.1}>
              <Letter animation={true} />
              <Sparkles size={2} color={"#fff"} scale={[10, 10, 10]}></Sparkles>

              <Scroll ref={scrollerRef} html style={{ width: "100%" }}>
                <HomeContent setPages={setPages} />
              </Scroll>
            </ScrollControls>
          </Canvas>
        ) : (
          <DialogBox />
        )}
      </>
    );
  }
};

export default Home;
